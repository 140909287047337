import React from 'react'
const content = {
    "Lifestyle Advice for Iron Deficiency - Anemia": {
      heading: "HAIR MOISTURE",
      title: "Lifestyle changes are crucial for managing iron deficiency anemia and improving iron levels. Here are some modifications that can help:",
      paragraphs:[
    
        {
            "strong": "Iron-Rich Diet:",
            "text": "Include lean meats, beans, lentils, tofu, dark leafy greens, nuts, and seeds."
        },
        {
            "strong": "Pair with Vitamin C:",
            "text": "Combine iron-rich foods with vitamin C sources like citrus fruits and bell peppers."
        },
        {
            "strong": "Avoid Iron Blockers:",
            "text": "Refrain from consuming calcium supplements, dairy, tea, and coffee during iron-rich meals."
        },
        {
            "strong": "Cook in Cast Iron:",
            "text": "Use cast iron cookware for acidic foods to increase iron content."
        },
        {
            "strong": "Limit Blood Donation (If advised):",
            "text": "Hold off on blood donation until iron levels improve."
        },
        {
            "strong": "Moderate Iron Inhibitors:",
            "text": "Consume soy products and high-fiber foods in moderation, paired with vitamin C."
        },
        {
            "strong": "Stay Hydrated:",
            "text": "Drink plenty of water to support nutrient absorption."
        },
        {
            "strong": "Limit Calcium Supplements:",
            "text": "Excessive calcium can hinder iron absorption, so use them only as needed."
        },
        {
            "strong": "Quit Smoking:",
            "text": "Smoking hinders iron absorption and overall health."
        },
        {
            "strong": "Moderate Alcohol:",
            "text": "Limit alcohol intake to support iron absorption."
        },
        {
            "strong": "Manage Menstrual Bleeding:",
            "text": "Discuss options with your healthcare provider for heavy menstrual bleeding."
        },
        {
            "strong": "Regular Check-ups:",
            "text": "Schedule regular visits to monitor iron levels and adjust treatment."
        },
        {
            "strong": "Physical Activity:",
            "text": "Engage in regular exercise with guidance from your healthcare provider, especially with severe anemia."
        }
    ]
    ,
    },
    "Lifestyle Advice for Thyroid": {
      heading: "HAIR MOISTURE",
      title: "Lifestyle Changes for Thyroid  ",
      paragraphs: [
        {
          strong: "",
          text: "Lifestyle changes are vital for managing thyroid conditions, whether hypothyroidism or hyperthyroidism. Here are some essential changes:      ",
        },
        {
          strong: "1) Take Thyroid Medication as Prescribed: ",
          text: "Consistency in medication is crucial for hormone level management.",
        },
        {
          strong: "2) Balanced Diet: ",
          text: "Focus on whole grains, fruits, vegetables, lean proteins, and healthy fats.",
        },
        {
          strong: "3) Limit Goitrogenic Foods (Hypothyroidism): ",
          text: "Reduce intake of foods like broccoli, cauliflower, and soy.",
        },
        {
          strong: "4) Monitor Iodine Intake (Hypothyroidism): ",
          text: "Ensure sufficient iodine from sources like iodized salt and fish.",
        },
        {
          strong: "5) Moderate Stimulants (Hyperthyroidism): ",
          text: "Limit caffeine and stimulants to manage symptoms.",
        },
        {
          strong: "6) Manage Stress: ",
          text: "Practice stress-reducing activities like meditation and yoga.",
        },
        {
          strong: "7) Adequate Sleep: ",
          text: "Ensure restful sleep for hormone regulation.",
        },
        {
          strong: "8) Regular Exercise: ",
          text: "Engage in physical activity suitable for your fitness level.",
        },
        {
          strong: "9) Stay Hydrated: ",
          text: "Drink plenty of water daily.",
        },
        {
          strong: "10) Limit Alcohol and Smoking: ",
          text: "Reduce or avoid alcohol and smoking.",
        },
        {
          strong: "11) Regular Check-ups: ",
          text: "Visit healthcare providers for thyroid function tests and adjustments to treatment.",
        },
        {
          strong: "12) Be Informed: ",
          text: "Educate yourself about your condition and treatment options.",
        },
        {
          strong: "13) Monitor Medication Interactions: ",
          text: "Check for interactions with other medications or supplements.",
        },
        {
          strong: "Seek Emotional Support: ",
          text: "Reach out to support groups or counsellors if needed.",
        },
      ],
    },
    "Lifestyle Advice for PCOD": {
      heading: "HAIR MOISTURE",
      title: "Lifestyle Advice to Manage PCOD Symptoms  ",
      paragraphs: [
        {
          strong: "1 ) ",
          text: "Our 7-day diet plan jumpstarts PCOS symptom management and weight loss, but lasting changes are key. Consider these lifestyle shifts: ",
          option:[
            {
                strong: "",
                text: "Regular exercise and incorporating Yoga Asanas and Pranayama.      ",
              },
              {
                strong: "",
                text: "Prioritize sufficient sleep and stress management. ",
              },
              {
                strong: "",
                text: "Avoid smoking and excessive alcohol.",
              },
              {
                strong: "",
                text: "Focus on nutrient-dense foods and maintain a healthy weight.   ",
              },
          ]
        },
      
        {
          strong: "2 ) ",
          text: "Exercise is vital for managing PCOS. Pairing a healthy diet with daily exercise aids weight loss and improves overall health. Exercise: ",
          option:[
            {
          strong: "",
          text: "Boosts insulin efficiency and aids in weight loss.      ",
        },
        {
          strong: "",
          text: "Prevents complications like heart disease and diabetes.      ",
        },
        {
          strong: "",
          text: "Improves mood, skin, and quality of sleep.      ",
        },
        {
          strong: "",
          text: "Includes aerobic activities and strength training for toned muscles.",
        },
          ]
        },
        
        {
          strong: "3 ) ",
          text: "Reduce exposure to toxins to support hormonal balance and manage PCOS symptoms. Tips include:",
          option:[
            {
                strong: "",
                text: "Choosing organic produce, especially items like apples and berries.",
              },
              {
                strong: "",
                text: "Using natural household cleaners like hydrogen peroxide and white vinegar.",
              },
              {
                strong: "",
                text: "Limiting alcohol and caffeine intake.",
              },
              {
                strong: "",
                text: "Checking cosmetics for endocrine-disrupting ingredients.",
              },
              {
                strong: "",
                text: "Our customized program supports women in managing PCOS, offering tailored solutions for weight loss and overall well-being.",
              },
          ]
        },
       
      ],
    },
    "Lifestyle Advice": {
      heading: "HAIR MOISTURE",
      title: "General Lifestyle Advice  ",
      paragraphs: [
        {
          strong: "",
          text: "1. Here are some lifestyle advice and tips for addressing hair loss and hair thinning:      ",
        },
        {
          strong: "Balanced Diet: ",
          text: "Follow a diet rich in protein, iron, biotin, zinc, and vitamins C and E.",
        },
        {
          strong: "Stay Hydrated: ",
          text: "Drink enough water daily.",
        },
        {
          strong: "Manage Stress: ",
          text: "Practice stress-reducing activities like meditation or yoga.",
        },
        {
          strong: "Regular Exercise: ",
          text: "Engage in physical activity to improve blood circulation to the scalp.",
        },
        {
          strong: "Avoid Over styling: ",
          text: "Limit heat styling, tight hairstyles, and chemical treatments.",
        },
        {
          strong: "Use Gentle Products: ",
          text: "Opt for mild, sulphate-free shampoos and conditioners.",
        },
        {
          strong: "Scalp Care: ",
          text: "Keep your scalp clean and free from excessive oil or dandruff.",
        },
  
        {
          strong: "Avoid Smoking and Limit Alcohol: ",
          text: "Both can negatively impact hair health.  ",
        },
        {
          strong: "Protect Hair: ",
          text: "Use hats or UV protection products and minimize exposure to pollution.",
        },
        {
          strong: "Get Enough Sleep: ",
          text: "Aim for 7-9 hours of quality sleep per night.",
        },
        {
          strong: "Consider Supplements: ",
          text: "Consult a healthcare professional for potential nutrient deficiencies.",
        },
        {
          strong: "Avoid Crash Diets: ",
          text: "Maintain a healthy, balanced diet. ",
        },
        {
          strong: "Regular Trims: ",
          text: "Trim hair regularly to remove split ends.",
        },
        {
          strong: "Avoid Tightly Woven Hair Accessories: ",
          text: "Choose looser hairstyles to prevent traction alopecia.",
        },
  
        {
          strong: "Seek Professional Advice: ",
          text: "Consult a dermatologist if hair loss persists.",
        },
      ],
      heading: "HAIR MOISTURE",
      title: "General Lifestyle Advice  ",
      paragraphs: [
        {
          strong:
            "2. General Dos and Don’ts to prevent hair loss and hair thinning        ",
          text: "",
        },
  
        {
          strong: "Balanced Diet:",
          text: "Eat nutrient-rich foods, especially protein, iron, biotin, zinc, and vitamins C and E.",
        },
        {
          strong: "Stay Hydrated: ",
          text: "Drink enough water daily.",
        },
        {
          strong: "Regular Exercise: ",
          text: "Engage in physical activity to boost blood circulation to the scalp.",
        },
  
        {
          strong: "Manage Stress: ",
          text: "Practice relaxation activities like meditation or yoga.",
        },
        {
          strong: "Gentle Hair Care: ",
          text: "Use mild, sulfate-free shampoos and conditioners.",
        },
        {
          strong: "Scalp Care: ",
          text: "Keep your scalp clean and free from excessive oil or dandruff.",
        },
  
        {
          strong: "Protect Hair: ",
          text: "Use hats or UV protection products and minimize pollution exposure.",
        },
        {
          strong: "Get Enough Sleep: ",
          text: "Aim for 7-9 hours of quality sleep per night.",
        },
        {
          strong: "Regular Trims: ",
          text: "Trim your hair regularly to remove split ends.",
        },
  
        {
          strong: "Don'ts",
          text: "",
        },
        {
          strong: "Avoid Overstyling: ",
          text: "Limit heat styling, tight hairstyles, and chemical treatments.",
        },
        {
          strong: "Avoid Smoking and Limit Alcohol: ",
          text: "Both can negatively impact hair health.",
        },
        {
          strong: "Avoid Tightly Hair Accessories: ",
          text: "Choose looser hairstyles to prevent hair thinning.",
        },
        {
          strong: "Avoid Crash Diets: ",
          text: "Maintain a balanced diet to prevent nutrient deficiencies.",
        },
        {
          strong: "Avoid Excessive Stress: ",
          text: "Manage stress with relaxation techniques.",
        },
  
        {
          strong: "Don't Neglect Scalp Health: ",
          text: "Keep your scalp clean and avoid using harsh chemicals.",
        },
        {
          strong: "Don't Delay Seeking Professional Advice: ",
          text: "Consult a dermatologist if hair loss persists.   ",
        },
      ],
    },
  };
export default function Page9({value}) {
    let data=content[value]
  return (
    <div className='a4-page avoid-break page-break-1'>
    <h1 className='lifeStyle-h1'>3) Lifestyle Management</h1>
    <div className=' nut-highlight'>
    <h2  style={{textAlign:'center'}}>{data.title}</h2>
    {data.paragraphs.map((it,index)=>{
        
               return <div>
               <p key={index}>
               <strong className='nut-head'>{it.strong}</strong> 
                {it.text}
             </p>
             {it.option&&it.option.map((item,index)=>{
return <ul>
        <li style={{fontSize:'1.4rem'}} key={index}>{item.text}</li></ul>
             })}
               </div>
           })}
           </div>
    </div>
  )
}
