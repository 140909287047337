import React from 'react'
const content = {
    "Diet for Iron Deficiency - Anemia": {
      heading: "Iron Deficiency - Anemia DIET",
   
      
      heading2: "4) FAQs",
      question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
      answer5: [
        {
          strong: "Can an iron deficiency diet help with hair loss?",
          text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
        },
        {
          strong: "How do I know if my hair loss is due to iron deficiency?",
          text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
        },
        {
          strong: "Best dietary sources of iron for hair loss?",
          text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
        },
        {
          strong: "Daily iron intake to combat hair loss?",
          text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
        },
        {
          strong:
            "Should I take iron supplements for hair loss due to iron deficiency?",
          text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
        },
        {
          strong:
            "Can I take iron supplements with other medications or supplements?",
          text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
        },
        {
          strong:
            "How long to see improvements in hair growth with an iron-rich diet?",
          text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
        },
        {
          strong:
            "Other factors contributing to hair loss besides iron deficiency? ",
          text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
        },
        {
          strong:
            "Side effects of consuming too much iron from diet or supplements? ",
          text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
        },
        {
          strong:
            "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
          text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
        },
      ],
    },
   "Hairloss Diet": {
    heading2: "4) FAQs",
    question4: "FREQUENTLY ASKED QUESTIONS FOR HAIR LOSS & HAIR THINNING? ",
    answer5: [
      {
        strong: "Can diet help prevent hair loss?        ",
        text: "Yes, a balanced diet rich in protein, iron, biotin, zinc, and omega-3 fatty acids can support hair health and may prevent some hair loss.        ",
      },
      {
        strong: "What nutrients are important for hair health?        ",
        text: "Protein, iron, biotin, zinc, omega-3 fatty acids, vitamin C, and vitamin E are crucial for hair health.        ",
      },
      {
        strong: "Can nutrient deficiencies cause hair loss?        ",
        text: "Yes, deficiencies in iron, biotin, and zinc can lead to hair loss and thinning.        ",
      },
      {
        strong: "What foods promote hair growth?        ",
        text: "Foods rich in protein (eggs, legumes, nuts), iron (spinach, lentils), biotin (avocado, nuts), and omega-3 fatty acids (fatty fish, chia seeds).        ",
      },
      {
        strong: "Can diet help with hair regrowth?        ",
        text: "A nutrient-rich diet may support hair regrowth, especially if hair loss is due to nutritional deficiencies, but it may not be sufficient for everyone.        ",
      },

      {
        strong: "Are there foods that worsen hair loss?        ",
        text: "Yes, foods high in processed sugars, unhealthy fats, and additives, as well as excessive alcohol and crash diets, can worsen hair loss.        ",
      },
      {
        strong: "Can vegan or vegetarian diets contribute to hair loss?        ",
        text: "Poorly planned vegan or vegetarian diets lacking vitamin B12, iron, and protein can contribute to hair loss.        ",
      },
      {
        strong: "Can supplements help with hair loss?        ",
        text: "Supplements with biotin, iron, and zinc may help, but should be used under healthcare guidance to avoid excess intake.        ",
      },
      {
        strong: "Does stress affect hair loss, and can diet help manage stress?        ",
        text: "Yes, stress can contribute to hair loss. A balanced diet rich in B-vitamins and magnesium can help manage stress.        ",
      },
      {
        strong: "How long to see improvements with a hair loss diet?        ",
        text: "Improvements may take several weeks to months with a nutrient-rich diet.        ",
      },
    ],
  },
   "Diet for PCOD": {
    heading2: "4) FAQs",
    question4: "FREQUENTLY ASKED QUESTIONS FOR PCOD DIET?    ",
    answer5: [
      {
        strong: "What foods should be included in a PCOS diet?            ",
        text: "A PCOS diet should focus on whole grains, fruits, vegetables, lean proteins, and healthy fats, while limiting processed foods, sugar, and unhealthy fats.            ",
      },
      {
        strong: "Can a PCOS diet help with weight loss?            ",
        text: "Yes, a balanced PCOS diet can aid in weight loss by regulating hormones and improving insulin resistance.            ",
      },
      {
        strong: "How many meals should be consumed in a PCOS diet?            ",
        text: "Aim for three meals and two snacks per day to stabilize blood sugar levels and prevent overeating.            ",
      },

      {
        strong: "Are dairy products recommended in a PCOS diet?            ",
        text: "Dairy can be consumed in moderation, preferably low-fat or non-fat options, to avoid exacerbating insulin resistance.            ",
      },
      {
        strong: "Should alcohol be avoided in a PCOS diet?            ",
        text: "It's best to limit or avoid alcohol, as it can disrupt hormone balance, raise blood sugar, and contribute to weight gain.            ",
      },
      {
        strong: "Is papaya healthy for PCOS?  ",
        text: "Papaya's antioxidants may help reduce inflammation and regulate hormones, potentially benefiting PCOS symptoms.            ",
      },
      {
        strong: "How healthy is eating banana for PCOS?            ",
        text: "Bananas provide fiber, vitamins, and minerals beneficial for PCOS, but moderation is key due to their calorie density.            ",
      },
      {
        strong: "Is wheat-based chapati good for PCOS?            ",
        text: "Chapati, being low-glycemic and rich in fiber, may help manage blood sugar levels, but caution is advised for those with celiac disease or gluten sensitivity.            ",
      },
      {
        strong: "Is poha good for PCOS?            ",
        text: "While research is limited, poha's low-glycemic index and fiber content may support blood sugar management, potentially benefiting PCOS.            ",
      },
    ],
  },
   
    "Diet for Thyroid": {
    heading2: "4) FAQs",
    question4: "FREQUENTLY ASKED QUESTIONS FOR THYROID DIET? ",
    answer5: [
      {
        strong:
          "What is a thyroid diet, and does it help with thyroid disorders?            ",
        text: "A thyroid diet is a meal plan that emphasizes foods rich in nutrients essential for thyroid function, such as iodine, selenium, zinc, and omega-3 fatty acids. While a thyroid diet alone may not cure thyroid disorders, it can support thyroid health and help manage symptoms when combined with proper medical treatment.            ",
      },
      {
        strong:
          "Are there specific foods I should avoid in a thyroid diet?            ",
        text: "Yes, some people with thyroid disorders may benefit from avoiding or limiting goitrogenic foods, such as cruciferous vegetables (e.g., broccoli, cauliflower, cabbage), soy products, and millet. Cooking these foods can reduce their goitrogenic effects.",
      },
      {
        strong:
          "Should I take iodine supplements for my thyroid condition?            ",
        text: "It is essential to consult with a healthcare provider before taking iodine supplements. Some thyroid disorders, like Hashimoto's thyroiditis, may be worsened by excess iodine intake. Iodine supplements are only recommended if there is a proven iodine deficiency.",
      },
      {
        strong:
          "How does selenium benefit thyroid health, and what are some good food sources of selenium?            ",
        text: "Selenium is necessary for converting thyroid hormones and protecting the thyroid gland from damage. Good food sources of selenium include Brazil nuts, seafood (e.g., tuna, shrimp), eggs, and whole grains.",
      },
      {
        strong:
          "Can certain foods worsen thyroid conditions, such as Hashimoto's or Graves' disease?            ",
        text: "For some individuals with autoimmune thyroid conditions like Hashimoto's or Graves' disease, consuming excessive iodine or goitrogenic foods may exacerbate their condition. However, individual responses to foods can vary, so it's essential to work with a healthcare provider or dietitian to determine the best dietary approach.",
      },
      {
        strong:
          "Is it safe to consume goitrogenic foods if I have a thyroid disorder?            ",
        text: "Yes, it is generally safe to consume goitrogenic foods in moderation, especially if they are cooked. Cooking reduces the goitrogenic compounds' effects, making them less likely to interfere with thyroid function.",
      },
      {
        strong:
          "How does caffeine and alcohol affect thyroid function?            ",
        text: "Excessive caffeine and alcohol consumption can interfere with thyroid hormone production and function, potentially leading to imbalances in hormone levels.",
      },
      {
        strong:
          "Can a specific diet help with weight management in thyroid disorders?            ",
        text: "A balanced diet combined with regular exercise can contribute to weight management in thyroid disorders. Some individuals with hypothyroidism may find it challenging to lose weight due to a slower metabolism, but a healthy diet can still support overall well-being.",
      },
      {
        strong:
          "Are there any vitamins or minerals I should include in my thyroid diet?            ",
        text: "Yes, essential nutrients for thyroid health include iodine, selenium, zinc, iron, and vitamin D. A well-balanced diet with a variety of nutrient-rich foods can help meet these requirements.",
      },
      {
        strong:
          "How does stress affect thyroid health, and what are some dietary strategies to manage stress?            ",
        text: "Stress can worsen thyroid symptoms, especially in individuals with autoimmune thyroid conditions. Dietary strategies to manage stress include consuming foods rich in B vitamins, magnesium, and omega-3 fatty acids, and avoiding excessive caffeine and alcohol.",
      },
      {
        strong: "",
        text: "Remember, individual dietary needs may vary, and it's crucial to work with a healthcare provider or a registered dietitian to develop a personalized thyroid diet plan based on your specific health condition and requirements.            ",
      },
    ],
  },
  };
export default function Page7({value}) {
  let data=content[value]

  return ( 
    <div className='a4-page avoid-break page-break-1'>
         <h1 className='nutrition-h1'>3) Nutritional Management</h1>
        <h2 style={{textAlign:'center'}}>{data.question4}</h2>
        <div className=' nut-highlight'>
        {data.answer5.map((item,index)=>{
          return    <div className='tick-con1'><img className='tick1' src='/question mark.jpg'/><div>
          <strong className='nut-head'>{item.strong}</strong>
          <p>{item.text}</p><img className='tick-mark' src='/tick mark.jpg'/>
        </div></div>
        })}
        </div>
    </div>
  )
}
