import React from 'react'
const content = {
    "Diet for Iron Deficiency - Anemia": {
      heading: "Iron Deficiency - Anemia DIET",
   
      question2: "SPECIFIC HAIRLOSS DIET FOR IRON DEFICIENCY ",
      mainAnswer3:
        "Here's a specific diet plan to address hair loss due to iron deficiency:",
        answer3: [
          {
            title: "Breakfast:",
            option: [
              {
                storng: "Spinach and Mushroom Omelette:",
                text: "Whisk 2 eggs with chopped spinach and mushrooms. Cook in olive oil. Serve with whole-grain toast.",
              },
              {
                storng: "Orange or Grapefruit",
                text: "Enjoy for a vitamin C boost.",
              },
            ],
          },
          {
            title: "Mid-Morning Snack:",
            option: [
              {
                storng: "Greek Yogurt with Berries:",
                text: "Top a cup of Greek yogurt with mixed berries.",
              },
            ],
          },
          {
            title: "Lunch:",
            option: [
              {
                storng: "Lentil Salad:",
                text: "Mix cooked lentils, tomatoes, cucumber, and parsley. Dress with lemon juice and olive oil.",
              },
              {
                storng: "Quinoa:",
                text: "Serve salad over quinoa for protein and zinc.",
              },
            ],
          },
          {
            title: "Afternoon Snack:",
            option: [
              {
                storng: "Handful of Almonds:",
                text: "Good source of iron, protein, and healthy fats.",
              },
             
            ],
          },
          {
            title: "Dinner:",
            option: [
              {
                storng: "Grilled Chicken with Spinach:",
                text: "Grill chicken breast and serve with sautéed spinach.",
              },
              {
                storng: "Brown Rice:",
                text: "Include a serving for additional zinc.",
              },
            ],
          },
          {
            title: "Evening Snack:",
            option: [
              {
                storng: "Dried Apricots:",
                text: "Rich in iron.",
              },
             
            ],
          },
          {
            title: "Before Bed:",
            option: [
              {
                storng: "Herbal Tea:",
                text: "Enjoy a cup of caffeine-free herbal tea like chamomile or peppermint.",
              },
             
            ],
          },
        ],
      question3: "Additional Tips:",
      answer4: [
        {
          text: "Stay hydrated with plenty of water.",
        },
        {
          text: "Avoid tea, coffee, or calcium supplements during meals to maximize iron absorption.",
        },
        {
          text: "Add vitamin C-rich foods like bell peppers, strawberries, and kiwi to enhance iron absorption.",
        },
      ],
      heading2: "4) FAQs",
      question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
      answer5: [
        {
          strong: "Can an iron deficiency diet help with hair loss?",
          text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
        },
        {
          strong: "How do I know if my hair loss is due to iron deficiency?",
          text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
        },
        {
          strong: "Best dietary sources of iron for hair loss?",
          text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
        },
        {
          strong: "Daily iron intake to combat hair loss?",
          text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
        },
        {
          strong:
            "Should I take iron supplements for hair loss due to iron deficiency?",
          text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
        },
        {
          strong:
            "Can I take iron supplements with other medications or supplements?",
          text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
        },
        {
          strong:
            "How long to see improvements in hair growth with an iron-rich diet?",
          text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
        },
        {
          strong:
            "Other factors contributing to hair loss besides iron deficiency? ",
          text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
        },
        {
          strong:
            "Side effects of consuming too much iron from diet or supplements? ",
          text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
        },
        {
          strong:
            "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
          text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
        },
      ],
    },
    "Hairloss Diet":  {
      heading: "SPECIFIC HAIRLOSS DIET",
      question:
      "1] Importance of Diet in Hair loss and hair thinning",
      subques:"A balanced diet is crucial for preventing hair loss and thinning. Key nutrients include:",
  
      answer: [
        {
         
          strong: "Vitamins, minerals, proteins, and healthy fats",
        },
        {
         
          strong: "Protein for hair structure and strength",
        },
        {
         
          strong: "Iron to promote growth and prevent shedding",
        },
        {
         
          strong: "Biotin for thickness",
        },
        {
       
          strong: "Omega-3 fatty acids for scalp health",
        },
        {
        
          strong: "Vitamin C for iron absorption and collagen production",
        },
        {
         
          strong: "Antioxidant-rich foods to protect follicles",
        },
        {
         
          strong: "Hormonal balance and hydration",
        },
        {
         
          strong: "Reducing inflammation caused by poor diet",
          text1:"Maintaining a well-balanced diet supports natural hair growth cycles, while crash diets can harm hair health."
        },
      ],
      question1: "2] What to eat and what to avoid?",
      mainAnswer1:
      "A) Foods You Can Eat If You Have Hair loss",
        subques1:"Incorporating nutrient-rich foods can support hair health and growth. Here are beneficial options:",
      answer1: [
        {
          strong: "Protein-rich foods:",
          text: "Eggs, fish, poultry, beans, lentils, tofu, and Greek yogurt for hair structure and strength.",
        },
        {
          strong: "Iron-rich foods:",
          text: "Spinach, kale, lentils, beans, red meat, and fortified cereals for oxygen delivery to hair follicles.",
        },
        {
          strong: "Biotin-rich foods:",
          text: "Eggs, nuts, seeds, sweet potatoes, and avocados to support hair health.",
        },
        {
          strong: "Omega-3 fatty acids:",
          text: "Fatty fish, chia seeds, and flaxseeds for anti-inflammatory scalp benefits.",
        },
        {
          strong: "Vitamin C-rich foods:",
          text: "Citrus fruits, strawberries, kiwi, bell peppers, and broccoli to aid iron absorption.",
        },
        {
          strong: "Zinc-rich foods: ",
          text: "Oysters, beef, pumpkin seeds, and chickpeas for hair growth and repair.",
        },
        {
          strong: "Selenium-rich foods:",
          text: "Brazil nuts, seafood, and whole grains to support thyroid function.",
        },
        {
          strong: "Antioxidant-rich foods:",
          text: "Berries, colourful vegetables, and green tea to protect follicles from oxidative stress.",
        },
        {
          strong: "Silica-rich foods:",
          text: "Cucumbers, bell peppers, and whole grains for hair strength and elasticity.",
        },
        {
          strong: "B-vitamin-rich foods:",
          text: "Whole grains, nuts, seeds, and leafy greens for healthy hair.",
        },
        {
          strong: "Collagen-rich foods:",
          text: "Bone broth, collagen supplements, and foods with gelatin for hair structure and strength.",
        },
        {
          strong: "Hydration:",
          text: "Drink plenty of water to maintain overall health and keep hair hydrated.",
        },
      ],
      mainAnswer2: "B) Foods to Avoid:",
      subAnswer3:'To support hair health, avoid or limit these foods:',
      answer2: [
        {
          "strong": "Highly Processed Foods:",
          "text": "Refined sugars, unhealthy fats, and additives can cause inflammation and oxidative stress."
        },
        {
          "strong": "Sugary Foods and Beverages:",
          "text": "High sugar intake can lead to insulin spikes, impacting hormone levels and contributing to hair loss."
        },
        {
          "strong": "Excessive Alcohol:",
          "text": "Can cause nutrient deficiencies and dehydration, negatively affecting hair growth."
        },
        {
          "strong": "High-Sodium Foods:",
          "text": "Excess salt can lead to water retention and poor circulation, impacting hair health."
        },
        {
          "strong": "Foods High in Trans Fats:",
          "text": "Found in fried and processed foods, trans fats promote inflammation and may hinder hair growth."
        },
        {
          "strong": "Foods with Artificial Additives:",
          "text": "Processed foods with additives and preservatives can negatively affect overall and hair health."
        },
        {
          "strong": "Excessive Caffeine:",
          "text": "Can lead to dehydration and affect nutrient absorption, potentially impacting hair health."
        },
        {
          "strong": "Large Amounts of Soy Products:",
          "text": "Phytoestrogens in soy can affect hormone levels and contribute to hair loss in some individuals."
        },
        {
          "strong": "Excess Vitamin A:",
          "text": "High amounts from supplements or certain foods can lead to hair loss."
        },
        {
          "strong": "Raw Egg Whites:",
          "text": "Contain avidin, which interferes with biotin absorption, leading to deficiency."
        },
        {
          "strong": "Crash Diets:",
          "text": "Extremely low-calorie diets can cause nutrient deficiencies, contributing to hair loss."
        }
      ]
      ,
      question2: "SPECIFIC HAIRLOSS DIET",
      mainAnswer3:
        "Here is a diet plan focused on hair health, incorporating foods rich in essential nutrients for promoting hair growth and preventing hair loss:",
        noneveg:"Veg / Non veg Diet Plan",
        answer3: [
          {
            title: "Breakfast:",
            option: [
              {
                strong: "Scrambled eggs with spinach and tomatoes:",
                text: "Rich in biotin, iron, and vitamin C. Serve with whole-grain toast (source of zinc and B-vitamins) and fresh fruit salad (contains antioxidants and vitamin C)."
              },
              {
                strong: "Oatmeal with sliced bananas and walnuts:",
                text: "Source of biotin, iron, and omega-3 fatty acids."
              },
              {
                strong: "Smoothie with spinach, kale, banana, chia seeds, and almond milk:",
                text: "Rich in iron, biotin, and omega-3 fatty acids."
              }
            ]
          },
          {
            title: "Lunch:",
            option: [
              {
                strong: "Grilled chicken or tofu salad with mixed greens, bell peppers, and avocado:",
                text: "Provides protein, iron, and healthy fats. Serve with quinoa or brown rice (source of protein and zinc)."
              },
              {
                strong: "Lentil soup with mixed vegetables:",
                text: "High in protein, iron, and zinc. Serve with whole-grain bread (provides B-vitamins)."
              },
              {
                strong: "Chickpea and vegetable stir-fry with a splash of lemon juice:",
                text: "Provides protein, vitamin C, and iron. Serve with brown rice or quinoa (source of zinc and B-vitamins)."
              }
            ]
          },
          {
            title: "Snack:",
            option: [
              {
                strong: "Greek yogurt with berries and a sprinkle of chia seeds:",
                text: "Contains protein, antioxidants, and omega-3 fatty acids."
              },
              {
                strong: "Carrot sticks with hummus:",
                text: "Contain silica and protein."
              },
              {
                strong: "Mixed nuts and seeds:",
                text: "Contain zinc, biotin, and omega-3 fatty acids."
              }
            ]
          },
          {
            title: "Dinner:",
            option: [
              {
                strong: "Baked salmon:",
                text: "Rich in omega-3 fatty acids. Serve with steamed broccoli (provides vitamin C and iron) and sweet potato (contains biotin and silica)."
              },
              {
                strong: "Grilled turkey or veggie burger with a side of mixed greens:",
                text: "Provides protein and antioxidants. Serve with quinoa salad with diced tomatoes and cucumbers (contains protein and silica)."
              },
              {
                strong: "Grilled chicken or tofu with a side of asparagus:",
                text: "Provides protein and biotin. Serve with roasted sweet potatoes (source of biotin and silica)."
              }
            ]
          },
         
        ],
        veg:"Vegetarian Diet Plan :",
        answer3a:[
          {
            title: "Breakfast:",
            option: [
              {
                strong: "Smoothie with spinach, banana, chia seeds, and almond milk:",
                text: "Rich in iron, biotin, and omega-3 fatty acids. Serve with whole-grain toast with avocado (source of healthy fats and B-vitamins)."
              },
              {
                strong: "Oatmeal with sliced bananas, walnuts, and a drizzle of honey:",
                text: "Source of biotin, iron, and zinc."
              },
              {
                strong: "Whole-grain toast with almond butter and sliced strawberries:",
                text: "Contains biotin and vitamin C."
              }
            ]
          },
          {
            title: "Lunch:",
            option: [
              {
                strong: "Chickpea and vegetable salad with mixed greens, bell peppers, cucumber, and tomatoes:",
                text: "Provides protein, iron, and antioxidants. Serve with quinoa or brown rice (source of protein and zinc)."
              },
              {
                strong: "Grilled vegetable wrap with hummus and mixed greens:",
                text: "Contains protein, antioxidants, and silica."
              },
              {
                strong: "Black bean and sweet potato tacos with avocado and salsa:",
                text: "Provides protein, iron, and healthy fats."
              }
            ]
          },
          {
            title: "Snack:",
            option: [
              {
                strong: "Greek yogurt with berries and a sprinkle of pumpkin seeds:",
                text: "Contains protein, antioxidants, and zinc."
              },
              {
                strong: "Carrot sticks with guacamole:",
                text: "Contains silica and healthy fats."
              },
              {
                strong: "Mixed nuts and dried fruits:",
                text: "Contains zinc, biotin, and omega-3 fatty acids."
              }
            ]
          },
          {
            title: "Dinner:",
            option: [
              {
                strong: "Lentil curry with spinach and tomatoes:",
                text: "High in protein, iron, and vitamin C. Serve with brown rice (source of zinc and B-vitamins) and steamed broccoli (provides vitamin C and iron)."
              },
              {
                strong: "Stir-fried tofu with broccoli, bell peppers, and mushrooms:",
                text: "Provides protein and iron. Serve with quinoa salad with diced tomatoes and cucumbers (contains protein and silica)."
              },
              {
                strong: "Vegetable curry with chickpeas and coconut milk:",
                text: "Rich in protein, iron, and zinc. Serve with brown rice (source of B-vitamins and zinc) and steamed kale (provides iron and vitamin C)."
              }
            ]
          },
          {
            title: "Hydration:",
            option: [
              {
                strong: "Hydration Note:",
                text: "Throughout the day, make sure to stay hydrated by drinking plenty of water. Additionally, consider including herbal teas like green tea or peppermint tea, which can contribute to scalp health. Herbal teas like green tea or hibiscus tea can also be included for their potential benefits for scalp health."
              }
            ]
          }
        ],
      question3: "Additional Tips:",
      answer4: [
        {
          text: "Stay hydrated with plenty of water.",
        },
        {
          text: "Avoid tea, coffee, or calcium supplements during meals to maximize iron absorption.",
        },
        {
          text: "Add vitamin C-rich foods like bell peppers, strawberries, and kiwi to enhance iron absorption.",
        },
      ],
      heading2: "4) FAQs",
      question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
      answer5: [
        {
          storng: "Can an iron deficiency diet help with hair loss?",
          text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
        },
        {
          storng: "How do I know if my hair loss is due to iron deficiency?",
          text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
        },
        {
          storng: "Best dietary sources of iron for hair loss?",
          text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
        },
        {
          storng: "Daily iron intake to combat hair loss?",
          text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
        },
        {
          storng:
            "Should I take iron supplements for hair loss due to iron deficiency?",
          text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
        },
        {
          storng:
            "Can I take iron supplements with other medications or supplements?",
          text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
        },
        {
          storng:
            "How long to see improvements in hair growth with an iron-rich diet?",
          text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
        },
        {
          storng:
            "Other factors contributing to hair loss besides iron deficiency? ",
          text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
        },
        {
          storng:
            "Side effects of consuming too much iron from diet or supplements? ",
          text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
        },
        {
          storng:
            "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
          text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
        },
      ],
    },
   
    "Diet for PCOD":  {
      heading: "3) SPECIFIC PCOD DIET",
      question:
      "1] Importance of Diet in Hair loss and hair thinning",
      subques:"A balanced diet is crucial for preventing hair loss and thinning. Key nutrients include:",
  
      answer: [
        {
         
          strong: "Vitamins, minerals, proteins, and healthy fats",
        },
        {
         
          strong: "Protein for hair structure and strength",
        },
        {
         
          strong: "Iron to promote growth and prevent shedding",
        },
        {
         
          strong: "Biotin for thickness",
        },
        {
       
          strong: "Omega-3 fatty acids for scalp health",
        },
        {
        
          strong: "Vitamin C for iron absorption and collagen production",
        },
        {
         
          strong: "Antioxidant-rich foods to protect follicles",
        },
        {
         
          strong: "Hormonal balance and hydration",
        },
        {
         
          strong: "Reducing inflammation caused by poor diet",
          text1:"Maintaining a well-balanced diet supports natural hair growth cycles, while crash diets can harm hair health."
        },
      ],
      question1: "2] What to eat and what to avoid?",
      mainAnswer1:
      "A) Foods You Can Eat If You Have Hair loss",
        subques1:"Incorporating nutrient-rich foods can support hair health and growth. Here are beneficial options:",
      answer1: [
        {
          strong: "Protein-rich foods:",
          text: "Eggs, fish, poultry, beans, lentils, tofu, and Greek yogurt for hair structure and strength.",
        },
        {
          strong: "Iron-rich foods:",
          text: "Spinach, kale, lentils, beans, red meat, and fortified cereals for oxygen delivery to hair follicles.",
        },
        {
          strong: "Biotin-rich foods:",
          text: "Eggs, nuts, seeds, sweet potatoes, and avocados to support hair health.",
        },
        {
          strong: "Omega-3 fatty acids:",
          text: "Fatty fish, chia seeds, and flaxseeds for anti-inflammatory scalp benefits.",
        },
        {
          strong: "Vitamin C-rich foods:",
          text: "Citrus fruits, strawberries, kiwi, bell peppers, and broccoli to aid iron absorption.",
        },
        {
          strong: "Zinc-rich foods: ",
          text: "Oysters, beef, pumpkin seeds, and chickpeas for hair growth and repair.",
        },
        {
          strong: "Selenium-rich foods:",
          text: "Brazil nuts, seafood, and whole grains to support thyroid function.",
        },
        {
          strong: "Antioxidant-rich foods:",
          text: "Berries, colourful vegetables, and green tea to protect follicles from oxidative stress.",
        },
        {
          strong: "Silica-rich foods:",
          text: "Cucumbers, bell peppers, and whole grains for hair strength and elasticity.",
        },
        {
          strong: "B-vitamin-rich foods:",
          text: "Whole grains, nuts, seeds, and leafy greens for healthy hair.",
        },
        {
          strong: "Collagen-rich foods:",
          text: "Bone broth, collagen supplements, and foods with gelatin for hair structure and strength.",
        },
        {
          strong: "Hydration:",
          text: "Drink plenty of water to maintain overall health and keep hair hydrated.",
        },
      ],
      mainAnswer2: "B) Foods to Avoid:",
      subAnswer3:'To support hair health, avoid or limit these foods:',
      answer2: [
        {
          "strong": "Highly Processed Foods:",
          "text": "Refined sugars, unhealthy fats, and additives can cause inflammation and oxidative stress."
        },
        {
          "strong": "Sugary Foods and Beverages:",
          "text": "High sugar intake can lead to insulin spikes, impacting hormone levels and contributing to hair loss."
        },
        {
          "strong": "Excessive Alcohol:",
          "text": "Can cause nutrient deficiencies and dehydration, negatively affecting hair growth."
        },
        {
          "strong": "High-Sodium Foods:",
          "text": "Excess salt can lead to water retention and poor circulation, impacting hair health."
        },
        {
          "strong": "Foods High in Trans Fats:",
          "text": "Found in fried and processed foods, trans fats promote inflammation and may hinder hair growth."
        },
        {
          "strong": "Foods with Artificial Additives:",
          "text": "Processed foods with additives and preservatives can negatively affect overall and hair health."
        },
        {
          "strong": "Excessive Caffeine:",
          "text": "Can lead to dehydration and affect nutrient absorption, potentially impacting hair health."
        },
        {
          "strong": "Large Amounts of Soy Products:",
          "text": "Phytoestrogens in soy can affect hormone levels and contribute to hair loss in some individuals."
        },
        {
          "strong": "Excess Vitamin A:",
          "text": "High amounts from supplements or certain foods can lead to hair loss."
        },
        {
          "strong": "Raw Egg Whites:",
          "text": "Contain avidin, which interferes with biotin absorption, leading to deficiency."
        },
        {
          "strong": "Crash Diets:",
          "text": "Extremely low-calorie diets can cause nutrient deficiencies, contributing to hair loss."
        }
      ]
      ,
      question2: "3) SPECIFIC PCOD DIET",
      mainAnswer3:"Looking for a customized diet plan for PCOD?",
       
        noneveg: "Lose Weight and Manage PCOS Symptoms - 7 DAY PCOS DIET PLAN:",
        answer3: [
          {
            title: "Breakfast (8:00-8:30AM) ",
            option: [
              {
                text: "2 Idli + 1/2 cup Sāmbhar + 1 tbsp Chutney",
        
              },
              {
                text: "2 Slices brown bread + 1 slice low-fat cheese + 2 boiled egg whites",
               
              },
              {
                text: "2 Chapatis + 1/2 cup paneer green peas curry",
               
              },
              {
                text:'2 Methi Parathas + 1 tbsp green chutney'
              },
              {
                text:'1 cup Vegetable Oats Upma + 1/2 cup low-fat milk'
              },
              {
                text:'1 cup Mix Veg Poha + 1/2 cup low-fat milk'
              },
              {
                text:'2 Besan Cheelas + 1 tbsp green chutney'
              },
            ]
          },
          {
            title: "Mid-Meal (11:00-11:30AM)",
            option: [
              {
                text: "1 cup green gram sprouts",
                
              },
              {
                text: "1 portion of papaya",
                
              },
              {
                text: "1/2 cup boiled black chana",
               
              },
              {
                text: "1 orange",
               
              },
              {
                text: "1 cup plain yogurt with raw/grilled vegetables",
               
              },
              {
                text: "1 cup plums",
               
              },
              {
                text: "1 cup boiled chana",
               
              },
            ]
          },
          {
            title: "Lunch (2:00-2:30PM) ",
            option: [
              {
                text: "2 Rotis + 1/2 cup salad + Fish curry (100g) + 1/2 cup cabbage sabji",
                
              },
              {
                text: "1 cup Veg Pulao with brown rice + 1/2 cup chicken curry + 1/2 cup buttermilk",
        
              },
              {
                text: "1 cup brown rice + 1/2 cup dal + 1/2 cup Palak sabji + 1/2 cup low-fat curd",
        
              },
              {
                text: "1 cup brown rice + chicken curry (150g) + 1 cup cucumber salad",
        
              },
              {
                text: "1 cup brown rice or 2 medium chapatis + 1/2 cup egg curry + 1/2 cup veg raita",
        
              },
              {
                text: "2 Chapatis + 1/2 cup cluster beans subji + 1/2 cup fish curry (100g)",
        
              },
              {
                text: "1 cup brown rice + 1/2 cup paneer curry + 1/2 cup capsicum subji + small cup low-fat curd",
        
              },
            ]
          },
          {
            title: "Evening (4:00-4:30PM)",
            option: [
              {
                text: "1 cup cut apple",
                
              },
              {
                text: "1 cup light tea + 2 wheat rusks",
               
              },
              {
                text: "1 guava",
                
              },
              {
                text:"1 cup light tea + 1 cup brown rice flakes poha"
              },
              {
                text:"1 cup boiled chana + 1 cup light tea"
              },
              {
                text:"1 cup tea + 2 karela biscuits"
              },
              {
                text:"1 cup strawberries"
              },
            ]
          },
          {
            title: "Dinner (8:00-8:30PM)",
            option: [
              {
                text: "2 Rotis/chapatis + 1/2 cup bottle gourd sabji",
                
              },
              {
                text: "2 Rotis/chapatis + 1/2 cup ladies' finger sabji",
               
              },
              {
                text: "1 cup broken oats upma + 1/2 cup green beans subji",
                
              },
              {
                text:"2 Wheat Rotis + 1/2 cup bitter gourd sabji"
              },
              {
                text:"2 Rotis/chapatis + 1/2 cup mixed veg curry"
              },
              {
                text:"2 Rotis/chapatis + 1/2 cup ridge gourd subji"
              },
              {
                text:"1 cup broken wheat dalia khichdi + 1/2 cup green beans subji"
              },
            ]
          },
         
        ],
       
      question3: "Tips for Sticking to the 7-Day Diet Plan",
      answer4: [
        {
          text: "Plan meals ahead.",
        },
        {
          text: "Shop for groceries early.",
        },
        {
          text: "Cook in bulk and freeze extras.",
        },
        {
          text: "Have healthy snacks available.",
        },
        {
          text: "Stay hydrated.",
        },
        {
          text: "Get ample sleep.",
        },
      ],
      heading2: "4) FAQs",
      question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
      answer5: [
        {
          storng: "Can an iron deficiency diet help with hair loss?",
          text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
        },
        {
          storng: "How do I know if my hair loss is due to iron deficiency?",
          text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
        },
        {
          storng: "Best dietary sources of iron for hair loss?",
          text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
        },
        {
          storng: "Daily iron intake to combat hair loss?",
          text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
        },
        {
          storng:
            "Should I take iron supplements for hair loss due to iron deficiency?",
          text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
        },
        {
          storng:
            "Can I take iron supplements with other medications or supplements?",
          text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
        },
        {
          storng:
            "How long to see improvements in hair growth with an iron-rich diet?",
          text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
        },
        {
          storng:
            "Other factors contributing to hair loss besides iron deficiency? ",
          text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
        },
        {
          storng:
            "Side effects of consuming too much iron from diet or supplements? ",
          text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
        },
        {
          storng:
            "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
          text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
        },
      ],
    },
   
    "Diet for Thyroid":  {
      heading: "General Diet Chart for Thyroid",
      question:
      "1] Importance of Diet in Hair loss due to Thyroid?",
      subques:"What is a thyroid diet plan? And why is it necessary?",
  
      answer: [
        {
         
          text: "A thyroid diet plan is essential for regulating thyroid function. It should be personalized to meet individual dietary needs. For example, including iodine-rich foods can help address iodine deficiency, while selenium and zinc can support healthy thyroid function. Thus, incorporating these foods into your diet chart is crucial for managing thyroid disease.",
        },
      ],
      question1: "2] What to eat and what to avoid?",
      mainAnswer1:
      "A) Foods You Can Eat If You Have Hair loss",
        subques1:"Incorporating nutrient-rich foods can support hair health and growth. Here are beneficial options:",
      answer1: [
        {
          strong: "Protein-rich foods:",
          text: "Eggs, fish, poultry, beans, lentils, tofu, and Greek yogurt for hair structure and strength.",
        },
        {
          strong: "Iron-rich foods:",
          text: "Spinach, kale, lentils, beans, red meat, and fortified cereals for oxygen delivery to hair follicles.",
        },
        {
          strong: "Biotin-rich foods:",
          text: "Eggs, nuts, seeds, sweet potatoes, and avocados to support hair health.",
        },
        {
          strong: "Omega-3 fatty acids:",
          text: "Fatty fish, chia seeds, and flaxseeds for anti-inflammatory scalp benefits.",
        },
        {
          strong: "Vitamin C-rich foods:",
          text: "Citrus fruits, strawberries, kiwi, bell peppers, and broccoli to aid iron absorption.",
        },
        {
          strong: "Zinc-rich foods: ",
          text: "Oysters, beef, pumpkin seeds, and chickpeas for hair growth and repair.",
        },
        {
          strong: "Selenium-rich foods:",
          text: "Brazil nuts, seafood, and whole grains to support thyroid function.",
        },
        {
          strong: "Antioxidant-rich foods:",
          text: "Berries, colourful vegetables, and green tea to protect follicles from oxidative stress.",
        },
        {
          strong: "Silica-rich foods:",
          text: "Cucumbers, bell peppers, and whole grains for hair strength and elasticity.",
        },
        {
          strong: "B-vitamin-rich foods:",
          text: "Whole grains, nuts, seeds, and leafy greens for healthy hair.",
        },
        {
          strong: "Collagen-rich foods:",
          text: "Bone broth, collagen supplements, and foods with gelatin for hair structure and strength.",
        },
        {
          strong: "Hydration:",
          text: "Drink plenty of water to maintain overall health and keep hair hydrated.",
        },
      ],
      mainAnswer2: "B) Foods to Avoid:",
      subAnswer3:'To support hair health, avoid or limit these foods:',
      answer2: [
        {
          "strong": "Highly Processed Foods:",
          "text": "Refined sugars, unhealthy fats, and additives can cause inflammation and oxidative stress."
        },
        {
          "strong": "Sugary Foods and Beverages:",
          "text": "High sugar intake can lead to insulin spikes, impacting hormone levels and contributing to hair loss."
        },
        {
          "strong": "Excessive Alcohol:",
          "text": "Can cause nutrient deficiencies and dehydration, negatively affecting hair growth."
        },
        {
          "strong": "High-Sodium Foods:",
          "text": "Excess salt can lead to water retention and poor circulation, impacting hair health."
        },
        {
          "strong": "Foods High in Trans Fats:",
          "text": "Found in fried and processed foods, trans fats promote inflammation and may hinder hair growth."
        },
        {
          "strong": "Foods with Artificial Additives:",
          "text": "Processed foods with additives and preservatives can negatively affect overall and hair health."
        },
        {
          "strong": "Excessive Caffeine:",
          "text": "Can lead to dehydration and affect nutrient absorption, potentially impacting hair health."
        },
        {
          "strong": "Large Amounts of Soy Products:",
          "text": "Phytoestrogens in soy can affect hormone levels and contribute to hair loss in some individuals."
        },
        {
          "strong": "Excess Vitamin A:",
          "text": "High amounts from supplements or certain foods can lead to hair loss."
        },
        {
          "strong": "Raw Egg Whites:",
          "text": "Contain avidin, which interferes with biotin absorption, leading to deficiency."
        },
        {
          "strong": "Crash Diets:",
          "text": "Extremely low-calorie diets can cause nutrient deficiencies, contributing to hair loss."
        }
      ]
      ,
      question2: "More Thyroid diet recipes:",
      // mainAnswer3:"Looking for a customized diet plan for PCOD?",
       
        // noneveg: "Lose Weight and Manage PCOS Symptoms - 7 DAY PCOS DIET PLAN:",
        answer3: [
          {
            title: "Pre-Breakfast (8:00 AM)",
            option: [
              { strong: "10 ML (1 TSP) APPLE CIDER VINEGAR IN 1 GLASS WARM WATER X 1 MONTH" }
            ]
          },
          {
            title: "Breakfast (9:00 AM)",
            option: [
              { strong: "GREEN SMOOTHIE: Blend together and take 1 glass for breakfast X 4 TIMES A WEEK" },
              { strong: "1 cucumber" },
              { strong: "1/2 cup washed Dhania/Parsley leaves" },
              { strong: "1/2 cup washed (with hot water) baby spinach" },
              { strong: "half TO quarter of an apple + 6-7 soaked almonds+ 1 tsp chia seeds" },
              { strong: "1/4 cup water+7-10 ml flax oil+ 1/2 lemon juice" },
              { strong: "pinch of pink salt + pinch roasted jeera" },
              { strong: "KIWI SMOOTHIE: Blend together and take 1 glass" },
              { strong: "1 kiwi + 1 cucumber + 6-7 almonds soaked peeled" },
              { strong: "1/2 spoon chia seeds+ 1/2 cup water+ protein powder 1/2 scoop" },
              { strong: "7 ml flax oil" },
              { strong: "CARROT SMOOTHIE: Blend together and take 1 glass" },
              { strong: "1 carrot+ 1 cucumber+ quarter apple" },
              { strong: "1 tbsp chia seeds+ 1/4 cup water+ lemon+ pink salt+ jeera" },
              { strong: "7 ml flax oil" },
              { strong: "handful pumpkin seeds" },
              { strong: "CELERY/APPLE DETOX SMOOTHIE: Blend together and take 1 glass" },
              { strong: "1/2 CUP CELERY+ 1 cucumber+ quarter apple" },
              { strong: "1 tbsp chia seeds" },
              { strong: "1/4 cup water+ lemon+ pink salt+ jeera" },
              { strong: "7 ml flax oil" },
              { strong: "handful pumpkin seeds" },
              { strong: "FAT PROTEIN SHAKE: Blend together and take 1 glass" },
              { strong: "1 SCOOP PLANT PROTEIN POWDER + 1/2 GLASS COCONUT WATER" },
              { strong: "1 TBSP CHIA SEEDS + 6-7 SOAKED ALMONDS" },
              { strong: "7ML FLAX OIL" },
              { strong: "ANY 1/2 CUP GREEN LEAFY VEGETABLE" },
              { strong: "1/2 FRUIT" }
            ]
          },
          {
            title: "Pre-Lunch (12:00 PM)",
            option: [
              { strong: "1 tsp of soaked chia seeds in lemon water. Soak for 20-25 mins." }
            ]
          },
          {
            title: "Lunch (1:00 PM)",
            option: [
              { strong: "1 bowl any home cooked vegetable of choice (Gobhi Matar/Mushroom Matter) + 1 Jowar roti or 1 sattu roti or buckwheat roti + 1 bowl homemade salad" },
              { strong: "1 bowl cooked sprouts + 1 Home cooked vegetable (tori/ tinda / lauki / cabbage / pumpkin) + 1 Gluten free chapati or 2-3 tbsp Rice LOW GI RICE (Avoid potato)" },
              { strong: "LOW GI FLOUR RECIPE: 1 tbsp Pumpkin/Flax seed powder(flour) + 1 tsp-soaked chia seeds + 2 TBSP URAD DAL FLOUR+ 3 TBSP Jowar flour+ pinch of salt+ Kasturi methi." },
              { strong: "1 Bowl Masoor Dal + 1 Bowl home cooked vegetable + 2-3 tbsp rice (BROWN/RED/BLACK/ LOW GI RICE)" },
              { strong: "1 small bowl vegetable Poha (80 % vegetables & 20% Poha) + 1 home cooked vegetable" },
              { strong: "1 bowl boiled quinoa + 1 black chana curry + 1 Bowl salad (cucumber + tomato + onion + coriander + lemon + chaat masala)" },
              { strong: "1 small bowl vegetable palak rice + 1 bowl dal" },
              { strong: "2 variety of home cooked vegetables (tori/ tinda/ lauki / cabbage/ pumpkin) + 1 small gluten free chapati" },
              { strong: "1 bowl vegetable Moong dal khichdi (1 tbsp rice + 90 percent dal+ vegetables) + 1 small bowl salad" },
              { strong: "1 vegetable Beetroot besan chilla + 1 bowl homemade salad." },
              { strong: "Coconut vegetables curry with 2-3 tbsp LOW GI rice" },
              { strong: "1 Buddha bowl: (Boiled chickpeas+ Grilled vegetables (BROCCOLI/ZUCCHINI/BELL PEPPERS) + homemade salsa + pumpkin seeds+ SMALL QTY Quinoa)" }
            ]
          },
          {
            title: "Post Lunch (2:00 PM)",
            option: [
              { strong: "1 CUP CINNAMON FENNEL TEA" }
            ]
          },
          {
            title: "Evening Snacks (5:00 PM)",
            option: [
              { strong: "3 brazil nuts + 4-5 pecan nuts + 3-4 walnuts" },
              { strong: "Roasted fox nuts chaat (makhane) 2-3 times a week" },
              { strong: "small bowl cooked sprouts + herbal tea or black tea/coffee" },
              { strong: "bullet coffee with 10 ml coconut oil or 1 tbsp coconut cream" },
              { strong: "handful of seeds+ Nuts (pumpkin+ sunflower + walnuts + almonds) W/ herbal tea coffee" }
            ]
          },
          {
            title: "Dinner (7:30 PM)",
            option: [
              { strong: "1 big bowl Mixed Vegetable soup carrot/peas/cauliflower (Add 1 spoon olive oil) + 4 pcs vegetable black chana or green pea vegetable kebabs w/green chutney." },
              { strong: "1 bowl khichdi (80% vegetables + 20 % dal/rice) + 1 bowl vegetable soup." },
              { strong: "Vegetable Dal chilla with added vegetables with one bowl soup (Pumpkin/ carrot/ ghiya)" },
              { strong: "1 bowl vegetable millet khichdi or Millet pulao (60 to 70 % vegetables) + 1 bowl soup." },
              { strong: "Whole green Moong dal+ Quinoa + Vegetables khichdi. Soak the dal + quinoa together and make vegetables khichdi. (70/80 Percent vegetables)" },
              { strong: "1 small bowl sweet potato chaat with 1 bowl soup mixed vegetable soup (Occasionally) once in 10 days sweet potato." },
              { strong: "1 bowl grilled TOFU satay (small qty) 70-80 grams + 2 cups vegetables WITH 10 ML olive oil extra virgin" },
              { strong: "lentil soup with vegetables + small bowl quinoa" },
              { strong: "Warm grilled Moong dal salad with vegetables and dressing of olive oil. (optional)" },
              { strong: "Tofu bhurji + 1 low carb flour chapati OR moong dal chilla" },
              { strong: "Tandoori vegetables (mushroom + broccoli + cauliflower + pineapple) marinate with tandoori masala + cashew or almond paste. Grill in oven." }
            ]
          }
        ],
       
      // question3: "Tips for Sticking to the 7-Day Diet Plan",
      // answer4: [
      //   {
      //     text: "Plan meals ahead.",
      //   },
      //   {
      //     text: "Shop for groceries early.",
      //   },
      //   {
      //     text: "Cook in bulk and freeze extras.",
      //   },
      //   {
      //     text: "Have healthy snacks available.",
      //   },
      //   {
      //     text: "Stay hydrated.",
      //   },
      //   {
      //     text: "Get ample sleep.",
      //   },
      // ],
      heading2: "4) FAQs",
      question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
      answer5: [
        {
          storng: "Can an iron deficiency diet help with hair loss?",
          text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
        },
        {
          storng: "How do I know if my hair loss is due to iron deficiency?",
          text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
        },
        {
          storng: "Best dietary sources of iron for hair loss?",
          text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
        },
        {
          storng: "Daily iron intake to combat hair loss?",
          text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
        },
        {
          storng:
            "Should I take iron supplements for hair loss due to iron deficiency?",
          text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
        },
        {
          storng:
            "Can I take iron supplements with other medications or supplements?",
          text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
        },
        {
          storng:
            "How long to see improvements in hair growth with an iron-rich diet?",
          text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
        },
        {
          storng:
            "Other factors contributing to hair loss besides iron deficiency? ",
          text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
        },
        {
          storng:
            "Side effects of consuming too much iron from diet or supplements? ",
          text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
        },
        {
          storng:
            "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
          text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
        },
      ],
    },
  };
export default function Page6({value}) {
  let data=content[value]

  return (
    <div className='a4-page avoid-break page-break-1'>
        <h1 className='nutrition-h1'>3) Nutritional Management</h1>
        <h2  style={{textAlign:'center'}}>{data.heading}</h2>
        <div className=' nut-highlight'>
<h2 className='nut-head'>{data.question2}</h2>
<h2>{data.mainAnswer3}</h2>
<h2 style={{margin:'1rem 0'}} className='nut-head'>{data.noneveg&&data.noneveg}</h2>
{data.answer3.map((item,index)=>{
          return  <div key={index}>    
          <div style={{textAlign:'center'}}><strong className='nut-head'>{item.title}</strong></div> 
           {item.option.map((it,index)=>{
               return  value==='Diet for PCOD'|| "Diet for Thyroid"?<ul><li style={{fontSize:'1.4rem'}}>{it.text}{it.strong}</li></ul>:  <p key={index}>
               <strong>{it.strong}</strong> 
                {it.text}
             </p>
           })}
        </div>
        })}
{data.veg&&<h2 style={{margin:'1rem 0'}} className='nut-head'>{data.veg}</h2>}
{data.answer3a?.map((item,index)=>{
          return<div key={index}>    
          <div style={{textAlign:'center'}}><strong className='nut-head'>{item.title}</strong></div> 
           {item.option.map((it,index)=>{
               return    <p key={index}>
               <strong>{it.strong}</strong> 
                {it.text}
             </p>
           })}
        </div>
        })}
        <h2 style={{textAlign:'center'}} className='nut-head' >{data.question3}</h2>
        {data.answer4&&data.answer4.map((item,index)=>{
        return  value==='Diet for PCOD'?<ul><li style={{fontSize:'1.4rem'}}>{item.text}</li></ul>
          : <div className='tick-con'><img className='tick' src='/tick mark.jpg'/><p key={index}>
          
          {item.text}
       </p></div>
        })}
        </div>
    </div>
  )
}
