import React, { useEffect, useState } from "react";
import Navbar from "./nav/Navbar";
import Footer from "./footer/Footer";
function TermsOfService(props) {
  return (
    <Navbar>
      <div className="container">
        <div
          style={{
            textAlign: "center",
            padding: "40px 0 0 0",
            fontSize: "26px",
            fontWeight: "600",
          }}
        >
          Terms of Service
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          Last updated on August 26, 2024
        </div>
        <div>
          This document is an electronic record in accordance with the
          Information Technology Act, 2000, and the applicable rules and
          amendments related to electronic records under various statutes. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          1. Introduction{" "}
        </div>
        <div>
          <ul style={{ margin: "10px 0 0 0" }}>
            <li>
              <div>
                These Terms of Service (“Terms”) govern your use of and access
                to the internal portal available at https://www.hairsncares.com/
                and the Products and Services (as defined below). The Website
                and its associated services are collectively referred to as the
                “Platform.”
              </div>
            </li>

            <li>
              <div>
                These Terms also incorporate any rules, guidelines, policies,
                additional terms, or disclaimers that we may provide or update
                from time to time. All such rules, guidelines, policies,
                additional terms, or disclaimers are considered a part of these
                Terms.
              </div>
            </li>

            <li>
              <div>
                These Terms constitute a binding legal agreement between Vplant
                Speciality Clinic (operating under the brand name
                “Hairsncares”), located at 101, Kane Plaza, Chincholi Bunder Rd,
                above Union Bank, near Carters Blue Hotel, Malad, Mindspace,
                Malad West, Mumbai, Maharashtra 400064, and you, the end-user of
                our Products or Services (“you”). By agreeing to these Terms,
                you confirm that you (i) have the legal capacity and authority
                to enter into this agreement, and (ii) are of the age of
                majority as defined by Indian law.
              </div>
            </li>

            <li>
              <div>
                By browsing, accessing, or using the Platform, or by purchasing
                our Products or Services, you confirm that you have read,
                understood, and agree to these Terms. If you do not agree with
                any of these Terms, please do not use the Platform or purchase
                the Products or Services. Access to the Platform is contingent
                upon your acceptance of all the terms, conditions, and notices
                outlined in these Terms, including any policies incorporated by
                reference, as well as any amendments we may make at our
                discretion.
              </div>
            </li>

            <li>
              <div>
                The Platform operates in compliance with Indian laws. If you
                access our Services from outside India, you do so at your own
                risk and are responsible for compliance with local laws. We do
                not guarantee that the content on the Platform is appropriate
                for use outside India. Use of third-party websites or services
                may be subject to additional terms and conditions.
              </div>
            </li>

            <li>
              <div>
                We reserve the right to modify these Terms or any policy at any
                time. Changes will be effective immediately upon posting on the
                Platform. By continuing to use the Platform or Services after
                any changes, you agree to the revised Terms. Please review these
                Terms regularly to stay informed about the terms and conditions
                that apply to your use of the Platform.{" "}
              </div>
            </li>
          </ul>
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          2. Our Services and Products
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            Services:
            <div>
              The Platform allows you to access and purchase various hair care
              Products, connect with hair coaches, engage in consultations with
              medical practitioners, perform hair health assessments, receive
              diagnoses, access recommended Products, avail of customized plans,
              track hair progress, and more. All services provided through the
              Platform, including the Platform itself, are collectively referred
              to as the “Services.”
            </div>
          </li>

          <li>
            Products:
            <div>
              The Platform facilitates the purchase of a range of hair care,
              hair growth, and health-related products, including
              nutraceuticals, food supplements, and Ayurvedic medicines
              (“Products”) sold by Hairsncares from its licensed premises.
              Products may be eligible for return or exchange in accordance with
              our Return, Refund, and Cancellation Policy.
            </div>
          </li>

          <li>
            Sale of Products:{" "}
            <ul style={{ margin: "10px 0 0 0" }}>
              <li>
                <div>
                  Listing a Product on the Platform is an invitation for you to
                  make an offer to purchase. Your placement of an order is your
                  offer to buy the Product(s) from us.
                </div>
              </li>

              <li>
                <div>
                  Once you place an order, you will receive an Order
                  Confirmation via email or SMS. This Order Confirmation is an
                  acknowledgment of receipt of your order and does not signify
                  acceptance of your offer
                </div>
              </li>

              <li>
                <div>
                  We only accept your offer when the Product is shipped or
                  dispatched to you, and a Shipment Confirmation is sent to your
                  registered email or mobile number. We reserve the right to
                  refuse or cancel any order before sending the Shipment
                  Confirmation.
                </div>
              </li>

              <li>
                <div>
                  If your order is dispatched in multiple packages, each
                  Shipment Confirmation and dispatch constitutes a separate
                  contract of sale for the Products specified.
                </div>
              </li>

              <li>
                <div>
                  Notifications and reminders regarding scheduled deliveries
                  will be sent to your registered email and/or mobile number.
                  Ensure your contact information is up to date.
                </div>
              </li>
              <li>
                <div>
                  Products are dispensed in quantities that align with the
                  requirements specified in the e-prescription.{" "}
                </div>
              </li>
              <li>
                <div>
                  All orders are subject to Product availability and adherence
                  to these Terms and applicable laws.{" "}
                </div>
              </li>
              <li>
                <div>
                  By purchasing Products from the Platform, you authorize us to
                  provide necessary declarations to governmental authorities,
                  including that the Products are for personal, non-commercial
                  use.{" "}
                </div>
              </li>
              <li>
                <div>
                  Prescription medicines are sold on the Platform against a
                  valid medical prescription issued by a qualified medical
                  practitioner.
                </div>
              </li>
            </ul>
          </li>

          <li>
            Completion of Sale:{" "}
            <div>
              Upon acceptance of your offer, Products will be dispensed at the
              pharmacy (Vplant pharmacy) as per the order terms. The sale is
              concluded at the retail pharmacy, with the invoice issued by us.
            </div>
          </li>

          <li>
            Purchase and Shipment of Products:
            <div>
              We reserve the right to refuse any order. Orders may be limited or
              canceled at our discretion. In the event of order changes or
              cancellations, we will notify you via email or phone. Products are
              prepared for shipment to ensure safe delivery, but delays due to
              factors beyond our control are possible.
            </div>
          </li>

          <li>
            Drugs Delivery:
            <div>
              Products are delivered by us or independent contractors, who act
              as your agents. We are not liable for any actions of these agents
              beyond standard delivery obligations{" "}
            </div>
          </li>

          <li>
            {" "}
            Terms for Availing Customized Plan:
            <ul style={{ margin: "10px 0 0 0" }}>
              <li>
                <div>
                  We offer an online portal for users to access hair wellness
                  solutions and services, including customized hair plans based
                  on hair tests and consultations.
                </div>
              </li>

              <li>
                <div>
                  Users must complete a Hair Test and provide accurate
                  information, which may include scalp images, health
                  information, and more.
                </div>
              </li>

              <li>
                <div>
                  Customized Plans are contingent upon approval by a registered
                  medical practitioner. The sale is only complete once Products
                  are shipped or dispatched.
                </div>
              </li>

              <li>
                <div>
                  The Customized Plan includes instructions for product use,
                  dietary and lifestyle habits, and guidance from a hair coach.
                </div>
              </li>
            </ul>
          </li>

          <li>
            {" "}
            Terms for Online/Tele Consultation:
            <ul style={{ margin: "10px 0 0 0" }}>
              <li>
                <div>
                  We facilitate healthcare products and services but do not
                  provide telemedical consultations. Information provided on the
                  Platform is for informational purposes only and does not
                  constitute medical advice
                </div>
              </li>

              <li>
                <div>
                  The provision of Information does not create a doctor-patient
                  relationship. Consultations with medical practitioners via the
                  Platform are independent opinions and do not reflect the views
                  of Hairsncares.
                </div>
              </li>

              <li>
                <div>
                  Services are not for use in medical emergencies or critical
                  health situations requiring prompt medical attention.
                </div>
              </li>
            </ul>
          </li>

          <li>
            {" "}
            Additional Terms:
            <ul style={{ margin: "10px 0 0 0" }}>
              <li>
                <div>
                  Calls scheduled for users may be rescheduled due to unforeseen
                  circumstances. Users should adhere to instructions received
                  through the Customized Plan.
                </div>
              </li>

              <li>
                <div>
                  For any discrepancies or issues, users should immediately
                  contact us at hairsncares@gmail.com or +91 9136028327.
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <div>
          By continuing to use the Platform or Services, you agree to adhere to
          these Terms and any changes or updates that may be made.
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          3. Eligibility of Use
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            Platform Access Requirements:
            <div>
              The Platform is accessible only to individuals who can legally
              enter into binding contracts under the Indian Contract Act, 1872.
              Individuals deemed "incompetent to contract" under the Indian
              Contract Act, 1872, including but not limited to minors and
              undischarged insolvents, are not eligible to use the Platform.
              Additionally, the Products and Services are not available to users
              who have been suspended or removed from our system for any reason.
              If you do not meet these eligibility criteria, you will not be
              allowed to request Products or Services through the Platform.
            </div>
          </li>

          <li>
            User Representation:
            <div>
              By using the Platform or Services, you confirm that you are an
              Indian national residing in India, of legal age to form a binding
              contract, and not prohibited from entering into a contract under
              applicable Indian laws. If you are under 18 years of age, you may
              use the Services only through your legal guardian, in compliance
              with relevant laws. We reserve the right to cancel your account or
              deny access to the Platform if we discover or are informed that
              you are under 18. We may also refuse access to new users or
              terminate access for existing users at our discretion, without
              providing any reason, and without recourse.
            </div>
          </li>

          <li>
            Account Restrictions:{" "}
            <div>
              You are permitted to have only one active account. Selling,
              trading, or transferring your account to another person is
              strictly prohibited.
            </div>
          </li>
        </ul>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          4. Account Creation
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            Registration Requirement:
            <div>
              To access certain features of the Platform, use Services, or
              purchase Products, you must register and create an account
              (“Account”).
            </div>
          </li>

          <li>
            Account Responsibilities:
            <div>You are responsible for:</div>
            <ul style={{ margin: "10px 0 0 0" }}>
              <li>
                <div>
                  Maintaining the confidentiality of your account and password
                  and restricting access to your device.
                </div>
              </li>

              <li>
                <div>
                  All activities conducted under your account or password. You
                  must immediately notify Hairsncares of any unauthorized use of
                  your account or breach of security.
                </div>
              </li>

              <li>
                <div>
                  Complying with the Telemedicine Practice Guidelines dated
                  March 25, 2020, and any other applicable laws, rules,
                  regulations, and guidelines.
                </div>
              </li>

              <li>
                <div>
                  Ensuring that you log out of your account at the end of each
                  session. Hairsncares is not liable for any loss or damage
                  resulting from your failure to maintain account
                  confidentiality.
                </div>
              </li>
            </ul>
          </li>

          <li>
            Account Information:{" "}
            <div>
              You guarantee that all information provided in connection with
              your account is complete, accurate, current, and truthful. You
              agree to promptly update your details on the Platform if there are
              any changes or modifications.
            </div>
          </li>

          <li>
            Account Security:
            <div>
              You are responsible for maintaining the confidentiality of your
              account and associated information and are fully accountable for
              all activities conducted under your account. Transactions, product
              delivery, or service use may be subject to additional terms or
              guidelines. If there is any conflict between the Terms and
              Additional Terms, the Additional Terms will prevail.
            </div>
          </li>

          <li>
            Liability Disclaimer:
            <div>
              We are not liable for any loss or damage resulting from your
              failure to comply with these account security guidelines. You may
              be held liable for losses incurred by us or other users due to
              unauthorized use of your account stemming from your failure to
              secure your account information. The Platform also allows limited
              access for unregistered users. Using another user's account to
              access Services or Products is strictly prohibited. If any
              information you provide is found to be false, inaccurate,
              outdated, or incomplete, we reserve the right to suspend or
              terminate your account and refuse current or future use of the
              Platform.
            </div>
          </li>

          <li>
            Payment Authorization:
            <div>
              You confirm that you are the authorized holder of the payment
              instrument used in transactions on the Platform. Hairsncares is
              not responsible for any financial loss, inconvenience, or mental
              distress resulting from misuse of your ID, password, credit card
              number, or account details.
            </div>
          </li>
        </ul>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          5. Payment Terms
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            Price Accuracy:
            <div>
              Prices are subject to change without notice, in accordance with
              applicable laws. While we strive to provide accurate product and
              price information, pricing errors may occasionally occur.
            </div>
          </li>

          <li>
            Full Price Payment:
            <div>
              Unless specified otherwise at checkout, you agree to pay the full
              price of the Products or Services. Discounts and offers are
              provided at our discretion, and we reserve the right to refuse or
              cancel the application of any offers or discounts on your order.
            </div>
          </li>

          <li>
            Pricing Errors:{" "}
            <div>
              We cannot confirm the price of a Product or Service until you
              place an order. If a Product or Service is listed at an incorrect
              price due to technical errors, we reserve the right to cancel any
              orders for that item unless it has already been delivered. If an
              item is incorrectly priced, we may contact you for instructions or
              cancel your order and notify you. Prices in your shopping cart
              reflect the most current information and may differ from when the
              item was initially added.
            </div>
          </li>

          <li>
            Return and Cancellation Policy:
            <div>
              Your eligibility for returns or cancellations is governed by our
              Returns, Refund, and Cancellation Policy.
            </div>
          </li>

          <li>
            Taxes:
            <div>
              Prices and fees are exclusive of applicable taxes, which will be
              added at checkout. Each user or third-party service provider is
              solely responsible for their tax obligations, and Hairsncares is
              not liable for any taxes except its own.
            </div>
          </li>

          <li>
            Additional Charges:{" "}
            <div>
              All commercial terms, such as price, delivery, and dispatch of
              Products and Services, are governed by direct contractual
              obligations between you and Hairsncares. Refunds are subject to
              compliance with these Terms. We reserve the right to refuse orders
              from users with a history of questionable charges or policy
              breaches.
            </div>
          </li>

          <li>
            Payment Processing:{" "}
            <div>
              We may require your bank account or credit card details to process
              payments for your orders. Payments are processed through
              third-party payment processors, and we are not responsible for any
              loss or damage resulting from this process, as these entities
              operate beyond our control.
            </div>
          </li>

          <li>
            Money Back Guarantee:{" "}
            <div>
              Users can review the detailed terms and conditions of our
              money-back guarantee on our website, Hairsncares.com.
            </div>
          </li>
        </ul>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          6. Third-Party Services
        </div>
        <div>
          While we strive to provide valuable insights and support, it is
          essential to acknowledge the following regarding results:
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            Use of Third-Party Services:{" "}
            <div>
              The Services may include content, documents, or information
              provided by third parties or contain links to third-party
              services. Use of such services is at your own risk.
            </div>
          </li>

          <li>
            Disclaimer:
            <div>
              Hairsncares makes no representations or warranties regarding
              third-party services and excludes all liability related to them.
              All intellectual property rights in third-party services are owned
              by their respective creators.
            </div>
          </li>
        </ul>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          7. Your Responsibilities
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            Accuracy of Information:{" "}
            <div>
              You confirm that all information provided on or through the
              Platform is complete, true, and accurate. If any information
              changes, you must immediately notify Hairsncares. We are not
              responsible for any loss or damage caused by incorrect or
              incomplete information provided by you.
            </div>
          </li>

          <li>
            Compliance:{" "}
            <div>
              You are solely responsible for complying with applicable laws and
              are liable for any breaches of your obligations.
            </div>
          </li>

          <li>
            Cooperation:{" "}
            <div>
              You agree to cooperate with Hairsncares in defending any
              proceedings arising from a breach of your obligations under these
              Terms.{" "}
            </div>
          </li>

          <li>
            Prohibited Activities:{" "}
            <div>
              You must not use the Platform or Services in any manner other than
              as permitted in these Terms. This includes but is not limited to,
              infringing on proprietary rights, copying or distributing content
              without authorization, transmitting harmful software, or engaging
              in activities that threaten the integrity or security of India.{" "}
            </div>
          </li>
        </ul>
        <strong>Cooperation for Marketing:</strong> You agree to cooperate in
        developing and sharing testimonials, case studies, marketing materials,
        and measurement criteria for the value derived from the Platform,
        Products, and Services.
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          8. INTELLECTUAL PROPERTY RIGHTS
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            <div>
              All information, content, materials, text, graphics, images,
              logos, button icons, software code, interface, design, and the
              arrangement of the content on the Platform are owned by us or our
              affiliates (referred to as “Company Content”) and are protected by
              copyright, trademark, and other applicable laws.
            </div>
          </li>

          <li>
            <div>
              The trademarks, logos, and service marks displayed on the Platform
              (“Marks”) are owned by Hairsncares or its third-party partners.
              You are not permitted to use these Marks without prior consent
              from us or the relevant third parties who may own these Marks.
              Unless otherwise stated, we own all intellectual property rights
              to the “Hairsncares” trademark, the domain name “Hairsncares.com,”
              and the Platform. This includes all rights, titles, and interests
              in copyright, related rights, patents, utility models, designs,
              know-how, trade secrets, inventions, goodwill, source code, meta
              tags, databases, text, content, graphics, icons, and hyperlinks.
            </div>
          </li>

          <li>
            <div>
              All product names, whether or not appearing with the trademark
              symbol, are trademarks that belong to us unless stated otherwise.
              Unauthorized use or misuse of these trademarks or any other
              materials, except as permitted in these Terms, is strictly
              prohibited and may violate copyright law, trademark law, and other
              applicable laws.
            </div>
          </li>

          <li>
            <div>
              The content on this Platform is subject to copyright protection.
              You are not allowed to copy, reproduce, redistribute, or republish
              any content from this Platform without our express written
              consent, except as permitted in these Terms.
            </div>
          </li>

          <li>
            <div>
              Hairsncares may request feedback, including bug reports, related
              to the Products or Services. Hairsncares reserves the right to
              use, copy, disclose, publish, display, distribute, and exploit
              this feedback without any obligation to pay royalties,
              acknowledge, or seek prior consent. By providing feedback, you
              waive any rights related to it and agree to execute any necessary
              documentation requested by Hairsncares.{" "}
            </div>
          </li>

          <li>
            <div>
              By submitting information, content, ideas, suggestions, or other
              materials (collectively, “Content”) to us, you grant us a
              non-exclusive, worldwide, perpetual, irrevocable, transferable,
              sublicensable, and royalty-free license to use, display, modify,
              adapt, translate, and create derivative works from this Content.
              This license allows us to operate the Platform and provide
              Services or Products, and to facilitate teleconsultations with
              medical practitioners and share information with hair coaches.
            </div>
          </li>

          <li>
            <div>
              When you use the Platform, you are granted a limited, revocable,
              non-exclusive license to access the Company Content. This license
              is solely for your personal use to avail of Services, identify
              Products, make purchases, and process returns and refunds.
              Commercial use is not permitted.{" "}
            </div>
          </li>

          <li>
            <div>
              The license granted does not allow for: (a) resale of Products or
              commercial use of the Platform or Company Content; (b) collection
              and use of product listings, descriptions, or prices; (c) any use
              of the Platform or Services other than as specified in these
              Terms; (d) downloading or copying of Account Information; (e) use
              of data mining, robots, or similar data gathering and extraction
              tools; or (f) creating or publishing a database that features
              parts of the Platform.
            </div>
          </li>

          <li>
            <div>
              Except as expressly stated in these Terms, no rights or licenses
              are granted to you regarding our or any third party’s intellectual
              property rights.{" "}
            </div>
          </li>
        </ul>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          9. TERM AND TERMINATION
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            <div>
              These Terms will remain in effect unless terminated as described
              here.
            </div>
          </li>

          <li>
            <div>
              Hairsncares reserves the right to terminate your access to or use
              of the Services at any time, at its sole discretion, if you breach
              any obligations, responsibilities, or covenants under these Terms.
            </div>
          </li>

          <li>
            Upon termination:
            <ul style={{ margin: "10px 0 0 0" }}>
              <li>
                <div>Your access to the Services will be revoked.</div>
              </li>

              <li>
                <div>
                  You will not be eligible to use any features of the Services
                  or purchase Products.
                </div>
              </li>

              <li>
                <div>
                  These Terms will terminate, except for clauses that are
                  intended to survive termination.
                </div>
              </li>
            </ul>
          </li>

          <li>
            <div>
              Upon termination, all outstanding payments or amounts due by you
              in relation to your use of the Services or purchase of Products
              must be paid immediately
            </div>
          </li>

          <li>
            <div>
              Any rights accrued before termination will continue to be valid
              after termination, and clauses that by their nature survive
              termination will remain binding.{" "}
            </div>
          </li>
        </ul>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          10. DISCLAIMERS OF WARRANTIES AND LIABILITIES
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            <div>Your use of the Services or Products is at your own risk.</div>
          </li>
          <li>
            <div>
              To the extent permitted by law, the Services are provided on an
              “as is” and “as available” basis. Hairsncares does not guarantee
              uninterrupted or error-free operation of the Platform or Services.
              We make no warranty that the Platform or Services will meet your
              requirements or expectations.
            </div>
          </li>
          <li>
            <div>
              To the maximum extent allowed by law, Hairsncares disclaims
              liability related to user content under intellectual property
              rights, libel, privacy, publicity, obscenity, or other laws. We
              also disclaim all liability for the misuse, loss, modification, or
              unavailability of any user content.
            </div>
          </li>
          <li>
            <div>
              Hairsncares strives to ensure that all information on the Platform
              is correct but makes no representations or warranties regarding
              the accuracy or completeness of any data, information, product, or
              service. We are not responsible for delays, inability to use the
              Platform, or for any information, software, products, or graphics
              obtained through the Platform
            </div>
          </li>
          <li>
            <div>
              To the fullest extent permissible by law, Hairsncares disclaims
              all warranties, express or implied, arising out of the Services
              and Products, including warranties of merchantability, fitness for
              a particular purpose, and non-infringement.
            </div>
          </li>
          <li>
            <div>
              You accept full responsibility for any consequences arising from
              your use of the Services or Products, and agree that Hairsncares
              has no liability for the same.
            </div>
          </li>{" "}
          <li>
            <div>
              To the fullest extent permissible by law, Hairsncares and its
              affiliates disclaim all liability for any loss or damage arising
              out of your use of, inability to use, or availability of the
              Services, including any Third Party Services. We are not liable
              for any indirect, incidental, special, or consequential damages.
            </div>
          </li>
          <li>
            <div>
              Hairsncares provides Services and Products in compliance with
              Indian laws. If you access or use the Services or Products outside
              of India, it is your responsibility to ensure that it is lawful to
              do so. Hairsncares is not liable for any differences between local
              laws and those in India.
            </div>
          </li>
        </ul>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          11. NOTICE AND TAKEDOWN
        </div>
        <div>
          If you believe any Content on the Platform violates any laws or Terms,
          please notify us immediately. We will make reasonable efforts to
          remove such content promptly.
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          12. INDEMNITY AND LIMITATION OF LIABILITY
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            <div>Your access to the Services will be revoked.</div>
          </li>

          <li>
            <div>
              You agree to indemnify and hold Hairsncares and its affiliates
              harmless from any claims, demands, losses, or liabilities arising
              from your use of the Services or Products, violation of these
              Terms, or violation of applicable laws.
            </div>
          </li>

          <li>
            <div>
              Hairsncares’ liability is limited to the amount charged for the
              specific transaction under which the liability arose. We are not
              responsible for any business loss or indirect or consequential
              loss.
            </div>
          </li>
        </ul>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          13. CONSENT TO USE DATA
        </div>
        <div>
          By using our Platform, you agree that Hairsncares may collect and use
          your information in accordance with our Privacy Policy.{" "}
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          14. MODIFICATION OF SERVICES AND PRODUCTS
        </div>
        <div>
          Hairsncares reserves the right to add, modify, or discontinue any
          Services or Products at any time without notice. We are not liable for
          any changes to the Services or Products.
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          15. GOVERNING LAW, JURISDICTION, AND DISPUTE RESOLUTION
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            <div>
              These Terms are governed by the laws of India, and any disputes
              shall be subject to the exclusive jurisdiction of courts in
              Mumbai, India.
            </div>
          </li>

          <li>
            <div>
              Any disputes arising from these Terms shall be resolved by
              arbitration in Ahmedabad in accordance with the Arbitration and
              Conciliation Act, 1996. The language of arbitration will be
              English.
            </div>
          </li>

          <li>
            <div>
              The parties shall keep the arbitration confidential, and the
              arbitrator’s decision shall be final and binding.
            </div>
          </li>

          <li>
            <div>
              Each party shall bear its own costs related to any dispute.{" "}
            </div>
          </li>
        </ul>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          16. MISCELLANEOUS
        </div>
        <ul style={{ margin: "10px 0 0 0" }}>
          <li>
            <div>
              Grievance Redressal: Contact our Grievance Officer, Dr. Amit
              Agarkar, for any grievances.
            </div>
          </li>

          <li>
            <div>
              Modification: Hairsncares reserves the right to modify these Terms
              at any time. Continued use of the Platform constitutes acceptance
              of the changes.
            </div>
          </li>

          <li>
            <div>
              Severability: If any provision of these Terms is found unlawful or
              unenforceable, the remaining provisions will continue in
              effect.sible for any business loss or indirect or consequential
              loss.
            </div>
          </li>

          <li>
            <div>
              Assignment: You cannot transfer your rights under these Terms
              without our prior written consent
            </div>
          </li>

          <li>
            <div>
              Notices: All notices to Hairsncares must be sent to our legal
              team.
            </div>
          </li>

          <li>
            <div>
              Third-Party Rights: No third party shall have rights under these
              Terms.
            </div>
          </li>

          <li>
            <div>
              Waiver: Failure to enforce any provision does not constitute a
              waiver of that provision.
            </div>
          </li>

          <li>
            <div>
              Force Majeure: We are not liable for any delay or failure to
              perform due to causes beyond our control.{" "}
            </div>
          </li>
        </ul>
      </div>
      <Footer />
    </Navbar>
  );
}

export default TermsOfService;
