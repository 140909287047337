import React from 'react'
import ReactSpeedometer from "react-d3-speedometer"

export default function SpeedoMeter({data}) {
  let value;
  if(data==='No stress (Green) (1-7)'){
    value=4
  }else if(data==='Mild to Moderate Level (Yellow) (7-14)'){
    value=10
  }else{
    value=18
  }
  return (
    <ReactSpeedometer
    maxValue={21}
    value={value}
    needleColor="black"
    segments={3}
    currentValueText="Stress Level"
    segmentColors={['green', 'yellow', 'red']}
    customSegmentLabels={[
      {
        text: 'Normal',
        position: 'INSIDE',
        color: '#fff',
      },
      {
        text: 'Moderate',
        position: 'INSIDE',
        color: '#555',
      },
      {
        text: 'Severe',
        position: 'INSIDE',
        color: '#fff',
      },
    ]}
  />
  )
}
