import React from 'react'
const content = {


    "Diet for PCOD":  {
      heading: "General Thyroid Diet Instructions:",
      question:
      "Smoothie Recipe – Super Folate Green Juice (High in Iron / Calcium / Folate)",
      subques:"All leafy vegetables have been very popular for years. It’s super high in iron and has a host of other benefits for your body, and can help to keep you from having a major crash in the middle of the day. This juice is also a really great source of micronutrients that your body can easily access. This recipe is a great morning drink that will not only energize you, but help you protect your blood sugar level as well. ",
  
      answer: [
        {
            "text": "I cup of green coriander (Dhania)"
        },
        {
            "text": "25-30 mint leaves"
        },
        {
            "text": "25 -30 curry leaves"
        },
        {
            "text": "1 Cucumber peeled"
        },
        {
            "text": "½ Lemon"
        },
        {
            "text": "¼ Apple peeled (Optional)"
        },
        {
            "text": "Pinch of pink Himalayan salt."
        },
        {
            "text": "5-6 almonds soaked and peeled."
        },
        {
            "text": "7- 10 ml flaxseed oil"
        },
        {
            "text": "1 tsp spoon chia seeds soaked in ¼ cup of water for 10 mins"
        },
        {
            "text": "Small piece of ginger"
        },
        {
            "text": "Pinch of roasted cumin"
        },
        {
            "text": "½ to 1 scoop plant protein"
        },
        {
            "text": "Unflavored or collagen"
        },
        {
            "text": "½ glass water"
        },
        {
            "strong": "Blend together and have"
        }
    ],
    
      question1: "2] What to eat and what to avoid?",
      mainAnswer1:
      "A) Foods You Can Eat If You Have Hair loss",
        subques1:"Incorporating nutrient-rich foods can support hair health and growth. Here are beneficial options:",
      answer1: [
        {
          strong: "Protein-rich foods:",
          text: "Eggs, fish, poultry, beans, lentils, tofu, and Greek yogurt for hair structure and strength.",
        },
        {
          strong: "Iron-rich foods:",
          text: "Spinach, kale, lentils, beans, red meat, and fortified cereals for oxygen delivery to hair follicles.",
        },
        {
          strong: "Biotin-rich foods:",
          text: "Eggs, nuts, seeds, sweet potatoes, and avocados to support hair health.",
        },
        {
          strong: "Omega-3 fatty acids:",
          text: "Fatty fish, chia seeds, and flaxseeds for anti-inflammatory scalp benefits.",
        },
        {
          strong: "Vitamin C-rich foods:",
          text: "Citrus fruits, strawberries, kiwi, bell peppers, and broccoli to aid iron absorption.",
        },
        {
          strong: "Zinc-rich foods: ",
          text: "Oysters, beef, pumpkin seeds, and chickpeas for hair growth and repair.",
        },
        {
          strong: "Selenium-rich foods:",
          text: "Brazil nuts, seafood, and whole grains to support thyroid function.",
        },
        {
          strong: "Antioxidant-rich foods:",
          text: "Berries, colourful vegetables, and green tea to protect follicles from oxidative stress.",
        },
        {
          strong: "Silica-rich foods:",
          text: "Cucumbers, bell peppers, and whole grains for hair strength and elasticity.",
        },
        {
          strong: "B-vitamin-rich foods:",
          text: "Whole grains, nuts, seeds, and leafy greens for healthy hair.",
        },
        {
          strong: "Collagen-rich foods:",
          text: "Bone broth, collagen supplements, and foods with gelatin for hair structure and strength.",
        },
        {
          strong: "Hydration:",
          text: "Drink plenty of water to maintain overall health and keep hair hydrated.",
        },
      ],
      mainAnswer2: "B) Foods to Avoid:",
      subAnswer3:'To support hair health, avoid or limit these foods:',
      answer2: [
        {
          "strong": "Highly Processed Foods:",
          "text": "Refined sugars, unhealthy fats, and additives can cause inflammation and oxidative stress."
        },
        {
          "strong": "Sugary Foods and Beverages:",
          "text": "High sugar intake can lead to insulin spikes, impacting hormone levels and contributing to hair loss."
        },
        {
          "strong": "Excessive Alcohol:",
          "text": "Can cause nutrient deficiencies and dehydration, negatively affecting hair growth."
        },
        {
          "strong": "High-Sodium Foods:",
          "text": "Excess salt can lead to water retention and poor circulation, impacting hair health."
        },
        {
          "strong": "Foods High in Trans Fats:",
          "text": "Found in fried and processed foods, trans fats promote inflammation and may hinder hair growth."
        },
        {
          "strong": "Foods with Artificial Additives:",
          "text": "Processed foods with additives and preservatives can negatively affect overall and hair health."
        },
        {
          "strong": "Excessive Caffeine:",
          "text": "Can lead to dehydration and affect nutrient absorption, potentially impacting hair health."
        },
        {
          "strong": "Large Amounts of Soy Products:",
          "text": "Phytoestrogens in soy can affect hormone levels and contribute to hair loss in some individuals."
        },
        {
          "strong": "Excess Vitamin A:",
          "text": "High amounts from supplements or certain foods can lead to hair loss."
        },
        {
          "strong": "Raw Egg Whites:",
          "text": "Contain avidin, which interferes with biotin absorption, leading to deficiency."
        },
        {
          "strong": "Crash Diets:",
          "text": "Extremely low-calorie diets can cause nutrient deficiencies, contributing to hair loss."
        }
      ]
      ,
      question2: "3) SPECIFIC PCOD DIET",
      mainAnswer3:"Looking for a customized diet plan for PCOD?",
       
        noneveg: "Lose Weight and Manage PCOS Symptoms - 7 DAY PCOS DIET PLAN:",
        answer3: [
          {
            title: "Breakfast (8:00-8:30AM) ",
            option: [
              {
                strong: "2 Idli + 1/2 cup Sāmbhar + 1 tbsp Chutney",

              },
              {
                strong: "2 Slices brown bread + 1 slice low-fat cheese + 2 boiled egg whites",
               
              },
              {
                strong: "2 Chapatis + 1/2 cup paneer green peas curry",
               
              },
              {
                strong:'2 Methi Parathas + 1 tbsp green chutney'
              },
              {
                strong:'1 cup Vegetable Oats Upma + 1/2 cup low-fat milk'
              },
              {
                strong:'1 cup Mix Veg Poha + 1/2 cup low-fat milk'
              },
              {
                strong:'2 Besan Cheelas + 1 tbsp green chutney'
              },
            ]
          },
          {
            title: "Mid-Meal (11:00-11:30AM)",
            option: [
              {
                strong: "1 cup green gram sprouts",
                
              },
              {
                strong: "1 portion of papaya",
                
              },
              {
                strong: "1/2 cup boiled black chana",
               
              },
              {
                strong: "1 orange",
               
              },
              {
                strong: "1 cup plain yogurt with raw/grilled vegetables",
               
              },
              {
                strong: "1 cup plums",
               
              },
              {
                strong: "1 cup boiled chana",
               
              },
            ]
          },
          {
            title: "Lunch (2:00-2:30PM) ",
            option: [
              {
                strong: "2 Rotis + 1/2 cup salad + Fish curry (100g) + 1/2 cup cabbage sabji",
                
              },
              {
                strong: "1 cup Veg Pulao with brown rice + 1/2 cup chicken curry + 1/2 cup buttermilk",

              },
              {
                strong: "1 cup brown rice + 1/2 cup dal + 1/2 cup Palak sabji + 1/2 cup low-fat curd",

              },
              {
                strong: "1 cup brown rice + chicken curry (150g) + 1 cup cucumber salad",

              },
              {
                strong: "1 cup brown rice or 2 medium chapatis + 1/2 cup egg curry + 1/2 cup veg raita",

              },
              {
                strong: "2 Chapatis + 1/2 cup cluster beans subji + 1/2 cup fish curry (100g)",

              },
              {
                strong: "1 cup brown rice + 1/2 cup paneer curry + 1/2 cup capsicum subji + small cup low-fat curd",

              },
            ]
          },
          {
            title: "Evening (4:00-4:30PM)",
            option: [
              {
                strong: "1 cup cut apple",
                
              },
              {
                strong: "1 cup light tea + 2 wheat rusks",
               
              },
              {
                strong: "1 guava",
                
              },
              {
                strong:"1 cup light tea + 1 cup brown rice flakes poha"
              },
              {
                strong:"1 cup boiled chana + 1 cup light tea"
              },
              {
                strong:"1 cup tea + 2 karela biscuits"
              },
              {
                strong:"1 cup strawberries"
              },
            ]
          },
          {
            title: "Dinner (8:00-8:30PM)",
            option: [
              {
                strong: "2 Rotis/chapatis + 1/2 cup bottle gourd sabji",
                
              },
              {
                strong: "2 Rotis/chapatis + 1/2 cup ladies' finger sabji",
               
              },
              {
                strong: "1 cup broken oats upma + 1/2 cup green beans subji",
                
              },
              {
                strong:"2 Wheat Rotis + 1/2 cup bitter gourd sabji"
              },
              {
                strong:"2 Rotis/chapatis + 1/2 cup mixed veg curry"
              },
              {
                strong:"2 Rotis/chapatis + 1/2 cup ridge gourd subji"
              },
              {
                strong:"1 cup broken wheat dalia khichdi + 1/2 cup green beans subji"
              },
            ]
          },
         
        ],
       
      question3: "Tips for Sticking to the 7-Day Diet Plan",
      answer4: [
        {
          text: "Plan meals ahead.",
        },
        {
          text: "Shop for groceries early.",
        },
        {
          text: "Cook in bulk and freeze extras.",
        },
        {
          text: "Have healthy snacks available.",
        },
        {
          text: "Stay hydrated.",
        },
        {
          text: "Get ample sleep.",
        },
      ],
      heading2: "4) FAQs",
      question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
      answer5: [
        {
          storng: "Can an iron deficiency diet help with hair loss?",
          text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
        },
        {
          storng: "How do I know if my hair loss is due to iron deficiency?",
          text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
        },
        {
          storng: "Best dietary sources of iron for hair loss?",
          text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
        },
        {
          storng: "Daily iron intake to combat hair loss?",
          text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
        },
        {
          storng:
            "Should I take iron supplements for hair loss due to iron deficiency?",
          text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
        },
        {
          storng:
            "Can I take iron supplements with other medications or supplements?",
          text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
        },
        {
          storng:
            "How long to see improvements in hair growth with an iron-rich diet?",
          text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
        },
        {
          storng:
            "Other factors contributing to hair loss besides iron deficiency? ",
          text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
        },
        {
          storng:
            "Side effects of consuming too much iron from diet or supplements? ",
          text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
        },
        {
          storng:
            "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
          text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
        },
      ],
    },
  };
export default function Page8({value}) {
  let data=content["Diet for PCOD"]
  const dietPlan = [
    { timing: 'Morning: 9:00 AM', diet: 'Jeevam Green Smoothie (recipe below)' },
    { timing: 'Pre - Lunch: 12:00 PM', diet: 'Soak 1 tsp of chia seeds for 20-25 mins in lemon water and have it' },
    { timing: 'Lunch: 01:00 PM', diet: '1 bowl boiled quinoa + 1 black chana curry + 1 Bowl salad (cucumber + tomato + onion + coriander + lemon + chaat masala)' },
    { timing: 'Evening: 05:00 PM', diet: '3 Brazil Nuts + 5 Pecan Nuts + 4 Walnuts' },
    { timing: 'Dinner: 07:00 PM', diet: '1 Full Plate Tandoori Vegetables (mushroom + broccoli + cauliflower + pineapple)' }
  ];
  return (
    <div className='a4-page avoid-break page-break-1'>
        <h1 className='nutrition-h1'>3) Nutritional Management</h1>
        <h2  style={{textAlign:'center'}}>{data.heading}</h2>
        <div className=' nut-highlight'>
        <strong className='nut-head'>{data.question}</strong>
        <p>{data.subques}</p>
        {data.answer.map((it,index)=>{
               return  <ul><li style={{fontSize:'1.4rem'}}><strong>{it.strong}</strong>{it.text}</li></ul>
           })}
        </div>
        <div><img style={{maxWidth:'100%'}} src='/untitled.jpg'/></div>
        <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th>Timing</th>
          <th>Diet</th>
        </tr>
      </thead>
      <tbody>
        {dietPlan.map((item, index) => (
          <tr key={index}>
            <td>{item.timing}</td>
            <td>{item.diet}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  )
}
