import React from 'react'
const content = {
  "Diet for Iron Deficiency - Anemia": {
    heading: "Iron Deficiency - Anemia DIET",
    question:
      "1] Importance of Diet in Hairloss due to Iron Deficiency - Anemia?",
    answer: [
      {
        strong: "Iron Importance:",
        text: "Vital for haemoglobin production, carrying oxygen in blood.",
      },
      {
        strong: "Anaemia Impact:",
        text: "Insufficient iron leads to anaemia, affecting oxygen delivery and hair health.",
      },
      {
        strong: "Follicle Effects:",
        text: "Iron deficiency weakens hair follicles, causing increased shedding.",
      },
      {
        strong: "Growth Cycle Disruption:",
        text: "Pushes more follicles into the resting phase, leading to dry, brittle hair.",
      },
      {
        strong: "Nutrient Absorption:",
        text: "Hampers absorption of key nutrients for hair growth like biotin and zinc.",
      },
      {
        strong: "Dietary Solutions:",
        text: "Focus on iron-rich foods like lean meats, beans, tofu, and dark leafy greens.",
      },
      {
        strong: "Enhanced Absorption:",
        text: "Pair iron-rich foods with vitamin C sources, avoid inhibitors like calcium supplements, tea, and coffee during meals.",
      },
    ],
    question1: "2] What to eat and what to avoid?",
    mainAnswer1:
      "A) Foods You Can Eat If You Have Hair loss due to Iron deficiency - Anemia",
    answer1: [
      {
        strong: "Lean Meats:",
        text: "Include red meat, poultry (chicken, turkey), and organ meats like liver, as they are excellent sources of heme iron, which is more easily absorbed by the body.",
      },
      {
        strong: "Beans and Lentils:",
        text: "Legumes like lentils, chickpeas, black beans, and kidney beans are rich in non-heme iron, a plant-based source of iron.",
      },
      {
        strong: "Tofu:",
        text: "Tofu and other soy products are good sources of non-heme iron for vegetarians and vegans.",
      },
      {
        strong: "Fortified Cereals:",
        text: "Choose iron-fortified cereals to boost your iron intake.",
      },
      {
        strong: "Dark Leafy Greens:",
        text: "Spinach, kale, and Swiss chard are rich in non-heme iron and also provide vitamin C, which aids iron absorption.",
      },
      {
        strong: "Nuts and Seeds:",
        text: "Incorporate almonds, pumpkin seeds, and sunflower seeds for their iron content.",
      },
      {
        strong: "Dried Fruits:",
        text: "Raisins, apricots, and prunes are iron-rich dried fruits.",
      },
      {
        strong: "Citrus Fruits:",
        text: "Oranges, grapefruits, lemons, and limes are high in vitamin C, which enhances iron absorption.",
      },
      {
        strong: "Berries:",
        text: "Strawberries, blueberries, raspberries, and blackberries also provide vitamin C.",
      },
    ],
    mainAnswer2: "B) Foods to Avoid (Inhibitors of Iron Absorption):",
    answer2: [
      {
        strong: "Avoid Calcium-Rich Foods:",
        text: "Skip calcium supplements, dairy, and fortified foods during meals to prevent hindering iron absorption.",
      },
      {
        strong: "Limit Tea and Coffee:",
        text: "Tannins in these beverages interfere with iron absorption, so it's best to avoid them during or right after meals.",
      },
      {
        strong: "Moderate High-Fiber Foods:",
        text: "While fiber is beneficial, consuming large amounts alongside iron-rich meals may reduce iron absorption. Separate high-fiber foods from iron-rich meals.",
      },
      {
        strong: "Pair Soy Products with Vitamin C:",
        text: "Tofu and soybeans are iron sources, but they contain compounds that can hinder absorption. Enhance absorption by pairing them with vitamin C-rich foods.",
      },
    ],
    question2: "3] SPECIFIC HAIRLOSS DIET FOR IRON DEFICIENCY ",
    mainAnswer3:
      "Here's a specific diet plan to address hair loss due to iron deficiency:",
    answer3: [
      {
        title: "Breakfast:",
        option: [
          {
            storng: "Spinach and Mushroom Omelette:",
            text: "Whisk 2 eggs with chopped spinach and mushrooms. Cook in olive oil. Serve with whole-grain toast.",
          },
          {
            storng: "Orange or Grapefruit",
            text: "Enjoy for a vitamin C boost.",
          },
        ],
      },
      {
        title: "Mid-Morning Snack:",
        option: [
          {
            storng: "Greek Yogurt with Berries:",
            text: "Top a cup of Greek yogurt with mixed berries.",
          },
        ],
      },
      {
        title: "Lunch:",
        option: [
          {
            storng: "Lentil Salad:",
            text: "Mix cooked lentils, tomatoes, cucumber, and parsley. Dress with lemon juice and olive oil.",
          },
          {
            storng: "Quinoa:",
            text: "Serve salad over quinoa for protein and zinc.",
          },
        ],
      },
      {
        title: "Afternoon Snack:",
        option: [
          {
            storng: "Handful of Almonds:",
            text: "Good source of iron, protein, and healthy fats.",
          },
         
        ],
      },
      {
        title: "Dinner:",
        option: [
          {
            storng: "Grilled Chicken with Spinach:",
            text: "Grill chicken breast and serve with sautéed spinach.",
          },
          {
            storng: "Brown Rice:",
            text: "Include a serving for additional zinc.",
          },
        ],
      },
      {
        title: "Evening Snack:",
        option: [
          {
            storng: "Dried Apricots:",
            text: "Rich in iron.",
          },
         
        ],
      },
      {
        title: "Before Bed:",
        option: [
          {
            storng: "Herbal Tea:",
            text: "Enjoy a cup of caffeine-free herbal tea like chamomile or peppermint.",
          },
         
        ],
      },
    ],
    question3: "Additional Tips:",
    answer4: [
      {
        text: "Stay hydrated with plenty of water.",
      },
      {
        text: "Avoid tea, coffee, or calcium supplements during meals to maximize iron absorption.",
      },
      {
        text: "Add vitamin C-rich foods like bell peppers, strawberries, and kiwi to enhance iron absorption.",
      },
    ],
    heading2: "4) FAQs",
    question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
    answer5: [
      {
        storng: "Can an iron deficiency diet help with hair loss?",
        text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
      },
      {
        storng: "How do I know if my hair loss is due to iron deficiency?",
        text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
      },
      {
        storng: "Best dietary sources of iron for hair loss?",
        text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
      },
      {
        storng: "Daily iron intake to combat hair loss?",
        text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
      },
      {
        storng:
          "Should I take iron supplements for hair loss due to iron deficiency?",
        text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
      },
      {
        storng:
          "Can I take iron supplements with other medications or supplements?",
        text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
      },
      {
        storng:
          "How long to see improvements in hair growth with an iron-rich diet?",
        text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
      },
      {
        storng:
          "Other factors contributing to hair loss besides iron deficiency? ",
        text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
      },
      {
        storng:
          "Side effects of consuming too much iron from diet or supplements? ",
        text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
      },
      {
        storng:
          "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
        text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
      },
    ],
  },
  "Hairloss Diet":  {
    heading: "Hairloss Diet",
    question:
    "1] Importance of Diet in Hair loss and hair thinning",
    subques:"A balanced diet is crucial for preventing hair loss and thinning. Key nutrients include:",

    answer: [
      {
       
        strong: "Vitamins, minerals, proteins, and healthy fats",
      },
      {
       
        strong: "Protein for hair structure and strength",
      },
      {
       
        strong: "Iron to promote growth and prevent shedding",
      },
      {
       
        strong: "Biotin for thickness",
      },
      {
     
        strong: "Omega-3 fatty acids for scalp health",
      },
      {
      
        strong: "Vitamin C for iron absorption and collagen production",
      },
      {
       
        strong: "Antioxidant-rich foods to protect follicles",
      },
      {
       
        strong: "Hormonal balance and hydration",
      },
      {
       
        strong: "Reducing inflammation caused by poor diet",
        text1:"Maintaining a well-balanced diet supports natural hair growth cycles, while crash diets can harm hair health."
      },
    ],
    question1: "2] What to eat and what to avoid?",
    mainAnswer1:
    "A) Foods You Can Eat If You Have Hair loss",
      subques1:"Incorporating nutrient-rich foods can support hair health and growth. Here are beneficial options:",
    answer1: [
      {
        strong: "Protein-rich foods:",
        text: "Eggs, fish, poultry, beans, lentils, tofu, and Greek yogurt for hair structure and strength.",
      },
      {
        strong: "Iron-rich foods:",
        text: "Spinach, kale, lentils, beans, red meat, and fortified cereals for oxygen delivery to hair follicles.",
      },
      {
        strong: "Biotin-rich foods:",
        text: "Eggs, nuts, seeds, sweet potatoes, and avocados to support hair health.",
      },
      {
        strong: "Omega-3 fatty acids:",
        text: "Fatty fish, chia seeds, and flaxseeds for anti-inflammatory scalp benefits.",
      },
      {
        strong: "Vitamin C-rich foods:",
        text: "Citrus fruits, strawberries, kiwi, bell peppers, and broccoli to aid iron absorption.",
      },
      {
        strong: "Zinc-rich foods: ",
        text: "Oysters, beef, pumpkin seeds, and chickpeas for hair growth and repair.",
      },
      {
        strong: "Selenium-rich foods:",
        text: "Brazil nuts, seafood, and whole grains to support thyroid function.",
      },
      {
        strong: "Antioxidant-rich foods:",
        text: "Berries, colourful vegetables, and green tea to protect follicles from oxidative stress.",
      },
      {
        strong: "Silica-rich foods:",
        text: "Cucumbers, bell peppers, and whole grains for hair strength and elasticity.",
      },
      {
        strong: "B-vitamin-rich foods:",
        text: "Whole grains, nuts, seeds, and leafy greens for healthy hair.",
      },
      {
        strong: "Collagen-rich foods:",
        text: "Bone broth, collagen supplements, and foods with gelatin for hair structure and strength.",
      },
      {
        strong: "Hydration:",
        text: "Drink plenty of water to maintain overall health and keep hair hydrated.",
      },
    ],
    mainAnswer2: "B) Foods to Avoid:",
    subAnswer3:'To support hair health, avoid or limit these foods:',
    answer2: [
      {
        "strong": "Highly Processed Foods:",
        "text": "Refined sugars, unhealthy fats, and additives can cause inflammation and oxidative stress."
      },
      {
        "strong": "Sugary Foods and Beverages:",
        "text": "High sugar intake can lead to insulin spikes, impacting hormone levels and contributing to hair loss."
      },
      {
        "strong": "Excessive Alcohol:",
        "text": "Can cause nutrient deficiencies and dehydration, negatively affecting hair growth."
      },
      {
        "strong": "High-Sodium Foods:",
        "text": "Excess salt can lead to water retention and poor circulation, impacting hair health."
      },
      {
        "strong": "Foods High in Trans Fats:",
        "text": "Found in fried and processed foods, trans fats promote inflammation and may hinder hair growth."
      },
      {
        "strong": "Foods with Artificial Additives:",
        "text": "Processed foods with additives and preservatives can negatively affect overall and hair health."
      },
      {
        "strong": "Excessive Caffeine:",
        "text": "Can lead to dehydration and affect nutrient absorption, potentially impacting hair health."
      },
      {
        "strong": "Large Amounts of Soy Products:",
        "text": "Phytoestrogens in soy can affect hormone levels and contribute to hair loss in some individuals."
      },
      {
        "strong": "Excess Vitamin A:",
        "text": "High amounts from supplements or certain foods can lead to hair loss."
      },
      {
        "strong": "Raw Egg Whites:",
        "text": "Contain avidin, which interferes with biotin absorption, leading to deficiency."
      },
      {
        "strong": "Crash Diets:",
        "text": "Extremely low-calorie diets can cause nutrient deficiencies, contributing to hair loss."
      }
    ]
    ,
    question2: "3] SPECIFIC HAIRLOSS DIET FOR IRON DEFICIENCY ",
    mainAnswer3:
      "Here's a specific diet plan to address hair loss due to iron deficiency:",
      answer3: [
        {
          title: "Breakfast:",
          option: [
            {
              strong: "Scrambled eggs with spinach and tomatoes:",
              text: "Rich in biotin, iron, and vitamin C. Serve with whole-grain toast (source of zinc and B-vitamins) and fresh fruit salad (contains antioxidants and vitamin C)."
            },
            {
              strong: "Oatmeal with sliced bananas and walnuts:",
              text: "Source of biotin, iron, and omega-3 fatty acids."
            },
            {
              strong: "Smoothie with spinach, kale, banana, chia seeds, and almond milk:",
              text: "Rich in iron, biotin, and omega-3 fatty acids."
            }
          ]
        },
        {
          title: "Lunch:",
          option: [
            {
              strong: "Grilled chicken or tofu salad with mixed greens, bell peppers, and avocado:",
              text: "Provides protein, iron, and healthy fats. Serve with quinoa or brown rice (source of protein and zinc)."
            },
            {
              strong: "Lentil soup with mixed vegetables:",
              text: "High in protein, iron, and zinc. Serve with whole-grain bread (provides B-vitamins)."
            },
            {
              strong: "Chickpea and vegetable stir-fry with a splash of lemon juice:",
              text: "Provides protein, vitamin C, and iron. Serve with brown rice or quinoa (source of zinc and B-vitamins)."
            }
          ]
        },
        {
          title: "Snack:",
          option: [
            {
              strong: "Greek yogurt with berries and a sprinkle of chia seeds:",
              text: "Contains protein, antioxidants, and omega-3 fatty acids."
            },
            {
              strong: "Carrot sticks with hummus:",
              text: "Contain silica and protein."
            },
            {
              strong: "Mixed nuts and seeds:",
              text: "Contain zinc, biotin, and omega-3 fatty acids."
            }
          ]
        },
        {
          title: "Dinner:",
          option: [
            {
              strong: "Baked salmon:",
              text: "Rich in omega-3 fatty acids. Serve with steamed broccoli (provides vitamin C and iron) and sweet potato (contains biotin and silica)."
            },
            {
              strong: "Grilled turkey or veggie burger with a side of mixed greens:",
              text: "Provides protein and antioxidants. Serve with quinoa salad with diced tomatoes and cucumbers (contains protein and silica)."
            },
            {
              strong: "Grilled chicken or tofu with a side of asparagus:",
              text: "Provides protein and biotin. Serve with roasted sweet potatoes (source of biotin and silica)."
            }
          ]
        },
       
      ],
      answer3a:[
        {
          title: "Breakfast:",
          option: [
            {
              strong: "Smoothie with spinach, banana, chia seeds, and almond milk:",
              text: "Rich in iron, biotin, and omega-3 fatty acids. Serve with whole-grain toast with avocado (source of healthy fats and B-vitamins)."
            },
            {
              strong: "Oatmeal with sliced bananas, walnuts, and a drizzle of honey:",
              text: "Source of biotin, iron, and zinc."
            },
            {
              strong: "Whole-grain toast with almond butter and sliced strawberries:",
              text: "Contains biotin and vitamin C."
            }
          ]
        },
        {
          title: "Lunch:",
          option: [
            {
              strong: "Chickpea and vegetable salad with mixed greens, bell peppers, cucumber, and tomatoes:",
              text: "Provides protein, iron, and antioxidants. Serve with quinoa or brown rice (source of protein and zinc)."
            },
            {
              strong: "Grilled vegetable wrap with hummus and mixed greens:",
              text: "Contains protein, antioxidants, and silica."
            },
            {
              strong: "Black bean and sweet potato tacos with avocado and salsa:",
              text: "Provides protein, iron, and healthy fats."
            }
          ]
        },
        {
          title: "Snack:",
          option: [
            {
              strong: "Greek yogurt with berries and a sprinkle of pumpkin seeds:",
              text: "Contains protein, antioxidants, and zinc."
            },
            {
              strong: "Carrot sticks with guacamole:",
              text: "Contains silica and healthy fats."
            },
            {
              strong: "Mixed nuts and dried fruits:",
              text: "Contains zinc, biotin, and omega-3 fatty acids."
            }
          ]
        },
        {
          title: "Dinner:",
          option: [
            {
              strong: "Lentil curry with spinach and tomatoes:",
              text: "High in protein, iron, and vitamin C. Serve with brown rice (source of zinc and B-vitamins) and steamed broccoli (provides vitamin C and iron)."
            },
            {
              strong: "Stir-fried tofu with broccoli, bell peppers, and mushrooms:",
              text: "Provides protein and iron. Serve with quinoa salad with diced tomatoes and cucumbers (contains protein and silica)."
            },
            {
              strong: "Vegetable curry with chickpeas and coconut milk:",
              text: "Rich in protein, iron, and zinc. Serve with brown rice (source of B-vitamins and zinc) and steamed kale (provides iron and vitamin C)."
            }
          ]
        },
        {
          title: "Hydration:",
          option: [
            {
              strong: "Hydration Note:",
              text: "Throughout the day, make sure to stay hydrated by drinking plenty of water. Additionally, consider including herbal teas like green tea or peppermint tea, which can contribute to scalp health. Herbal teas like green tea or hibiscus tea can also be included for their potential benefits for scalp health."
            }
          ]
        }
      ],
    question3: "Additional Tips:",
    answer4: [
      {
        text: "Stay hydrated with plenty of water.",
      },
      {
        text: "Avoid tea, coffee, or calcium supplements during meals to maximize iron absorption.",
      },
      {
        text: "Add vitamin C-rich foods like bell peppers, strawberries, and kiwi to enhance iron absorption.",
      },
    ],
    heading2: "4) FAQs",
    question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
    answer5: [
      {
        storng: "Can an iron deficiency diet help with hair loss?",
        text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
      },
      {
        storng: "How do I know if my hair loss is due to iron deficiency?",
        text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
      },
      {
        storng: "Best dietary sources of iron for hair loss?",
        text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
      },
      {
        storng: "Daily iron intake to combat hair loss?",
        text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
      },
      {
        storng:
          "Should I take iron supplements for hair loss due to iron deficiency?",
        text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
      },
      {
        storng:
          "Can I take iron supplements with other medications or supplements?",
        text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
      },
      {
        storng:
          "How long to see improvements in hair growth with an iron-rich diet?",
        text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
      },
      {
        storng:
          "Other factors contributing to hair loss besides iron deficiency? ",
        text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
      },
      {
        storng:
          "Side effects of consuming too much iron from diet or supplements? ",
        text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
      },
      {
        storng:
          "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
        text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
      },
    ],
  },

  "Diet for PCOD":  {
    heading: "GENERAL PCOD DIET",
    question:
    "Most women with PCOS are overweight, but some are thin.",
    subques:"Lifestyle changes are key to managing PCOS:",

    answer: [
      {
       
        strong: "Eat a healthy, balanced diet.",
      },
      {
       
        strong: "Exercise regularly.",
      },
      {
       
        strong: "Aim to lose weight.",
      },
      {
       
        strong: "Reduce stress and anxiety.",
      },
     
    ],
    question1: "2] What to eat and what to avoid?",
    mainAnswer1:
    "A) Foods You Can Eat If You Have PCOS",
      subques1:"Incorporating nutrient-rich foods can support hair health and growth. Here are beneficial options:",
    answer1: [
      {
        strong: "Protein-rich foods:",
        text:[{text:'Slows digestion and controls blood sugar'},
          {text:"Examples: Broccoli, cauliflower, peppers, beans, gourds, sweet potatoes, pumpkin."}
        ] 
      },
      {
        strong: "Lean Proteins:",
        text:[{text:'Keeps you fuller longer and stabilizes blood sugar.'},
          {text:"Examples: Chicken, fish, egg whites."}
        ] 
      },
      {
        strong: "Anti-Inflammatory Foods:",
        text:[{text:'Reduces PCOS symptoms.'},
          {text:"Examples: Tomatoes, spinach, strawberries, walnuts, almonds, turmeric, sardines, salmon."}
        ] 
      },
      {
        strong: "Whole-Grain Foods:",
        text:[{text:'Focus on whole, plant-based foods: fruits, vegetables, nuts, seeds, and whole grains.'},
          {text:'Benefits:'}
        ]
            
      },
      
    
    ],
    textPOCD:[
      {
        text:"Decreases saturated fat, trans fat, and salt."
      },
      {
        text:'Increases vegetable variety.'
      },
      {
        text:"Encourages small, frequent meals."
      },
      {
        text:'Promotes healthy snacks: fruits, vegetables, nuts.'
      }
    ],
  
    mainAnswer2: "B) Foods to Avoid If You Have PCOS ",
    subAnswer3:'To support hair health, avoid or limit these foods:',
    answer2: [
      {
        "strong": "Avoid Refined Carbohydrates: ",
        "text": "White bread, pastries, muffins, and white potatoes increase insulin resistance and inflammation, worsening PCOS symptoms."
      },
      {
        "strong": "Avoid Sugary Snacks and Drinks:",
        "text": "Excess sugar contributes to insulin resistance. Check food labels for hidden sugars like sucrose, dextrose, and high fructose corn syrup."
      },
      {
        "strong": "Limit High-Energy Fruits:",
        "text": "Examples include bananas, jackfruit, mangoes, chikoo, lychees, watermelon, and dates."
      },
      {
        "strong": "Avoid Inflammatory Foods:",
        "text": "Foods like French fries, margarine, red meat, and processed meats aggravate PCOS symptoms."
      },
     
     
     
     
    ]
    ,
    question2: "3] SPECIFIC HAIRLOSS DIET FOR IRON DEFICIENCY ",
    mainAnswer3:
      "Here's a specific diet plan to address hair loss due to iron deficiency:",
      answer3: [
        {
          title: "Breakfast:",
          option: [
            {
              strong: "Scrambled eggs with spinach and tomatoes:",
              text: "Rich in biotin, iron, and vitamin C. Serve with whole-grain toast (source of zinc and B-vitamins) and fresh fruit salad (contains antioxidants and vitamin C)."
            },
            {
              strong: "Oatmeal with sliced bananas and walnuts:",
              text: "Source of biotin, iron, and omega-3 fatty acids."
            },
            {
              strong: "Smoothie with spinach, kale, banana, chia seeds, and almond milk:",
              text: "Rich in iron, biotin, and omega-3 fatty acids."
            }
          ]
        },
        {
          title: "Lunch:",
          option: [
            {
              strong: "Grilled chicken or tofu salad with mixed greens, bell peppers, and avocado:",
              text: "Provides protein, iron, and healthy fats. Serve with quinoa or brown rice (source of protein and zinc)."
            },
            {
              strong: "Lentil soup with mixed vegetables:",
              text: "High in protein, iron, and zinc. Serve with whole-grain bread (provides B-vitamins)."
            },
            {
              strong: "Chickpea and vegetable stir-fry with a splash of lemon juice:",
              text: "Provides protein, vitamin C, and iron. Serve with brown rice or quinoa (source of zinc and B-vitamins)."
            }
          ]
        },
        {
          title: "Snack:",
          option: [
            {
              strong: "Greek yogurt with berries and a sprinkle of chia seeds:",
              text: "Contains protein, antioxidants, and omega-3 fatty acids."
            },
            {
              strong: "Carrot sticks with hummus:",
              text: "Contain silica and protein."
            },
            {
              strong: "Mixed nuts and seeds:",
              text: "Contain zinc, biotin, and omega-3 fatty acids."
            }
          ]
        },
        {
          title: "Dinner:",
          option: [
            {
              strong: "Baked salmon:",
              text: "Rich in omega-3 fatty acids. Serve with steamed broccoli (provides vitamin C and iron) and sweet potato (contains biotin and silica)."
            },
            {
              strong: "Grilled turkey or veggie burger with a side of mixed greens:",
              text: "Provides protein and antioxidants. Serve with quinoa salad with diced tomatoes and cucumbers (contains protein and silica)."
            },
            {
              strong: "Grilled chicken or tofu with a side of asparagus:",
              text: "Provides protein and biotin. Serve with roasted sweet potatoes (source of biotin and silica)."
            }
          ]
        },
       
      ],
      answer3a:[
        {
          title: "Breakfast:",
          option: [
            {
              strong: "Smoothie with spinach, banana, chia seeds, and almond milk:",
              text: "Rich in iron, biotin, and omega-3 fatty acids. Serve with whole-grain toast with avocado (source of healthy fats and B-vitamins)."
            },
            {
              strong: "Oatmeal with sliced bananas, walnuts, and a drizzle of honey:",
              text: "Source of biotin, iron, and zinc."
            },
            {
              strong: "Whole-grain toast with almond butter and sliced strawberries:",
              text: "Contains biotin and vitamin C."
            }
          ]
        },
        {
          title: "Lunch:",
          option: [
            {
              strong: "Chickpea and vegetable salad with mixed greens, bell peppers, cucumber, and tomatoes:",
              text: "Provides protein, iron, and antioxidants. Serve with quinoa or brown rice (source of protein and zinc)."
            },
            {
              strong: "Grilled vegetable wrap with hummus and mixed greens:",
              text: "Contains protein, antioxidants, and silica."
            },
            {
              strong: "Black bean and sweet potato tacos with avocado and salsa:",
              text: "Provides protein, iron, and healthy fats."
            }
          ]
        },
        {
          title: "Snack:",
          option: [
            {
              strong: "Greek yogurt with berries and a sprinkle of pumpkin seeds:",
              text: "Contains protein, antioxidants, and zinc."
            },
            {
              strong: "Carrot sticks with guacamole:",
              text: "Contains silica and healthy fats."
            },
            {
              strong: "Mixed nuts and dried fruits:",
              text: "Contains zinc, biotin, and omega-3 fatty acids."
            }
          ]
        },
        {
          title: "Dinner:",
          option: [
            {
              strong: "Lentil curry with spinach and tomatoes:",
              text: "High in protein, iron, and vitamin C. Serve with brown rice (source of zinc and B-vitamins) and steamed broccoli (provides vitamin C and iron)."
            },
            {
              strong: "Stir-fried tofu with broccoli, bell peppers, and mushrooms:",
              text: "Provides protein and iron. Serve with quinoa salad with diced tomatoes and cucumbers (contains protein and silica)."
            },
            {
              strong: "Vegetable curry with chickpeas and coconut milk:",
              text: "Rich in protein, iron, and zinc. Serve with brown rice (source of B-vitamins and zinc) and steamed kale (provides iron and vitamin C)."
            }
          ]
        },
        {
          title: "Hydration:",
          option: [
            {
              strong: "Hydration Note:",
              text: "Throughout the day, make sure to stay hydrated by drinking plenty of water. Additionally, consider including herbal teas like green tea or peppermint tea, which can contribute to scalp health. Herbal teas like green tea or hibiscus tea can also be included for their potential benefits for scalp health."
            }
          ]
        }
      ],
    question3: "Additional Tips:",
    answer4: [
      {
        text: "Stay hydrated with plenty of water.",
      },
      {
        text: "Avoid tea, coffee, or calcium supplements during meals to maximize iron absorption.",
      },
      {
        text: "Add vitamin C-rich foods like bell peppers, strawberries, and kiwi to enhance iron absorption.",
      },
    ],
    heading2: "4) FAQs",
    question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
    answer5: [
      {
        storng: "Can an iron deficiency diet help with hair loss?",
        text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
      },
      {
        storng: "How do I know if my hair loss is due to iron deficiency?",
        text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
      },
      {
        storng: "Best dietary sources of iron for hair loss?",
        text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
      },
      {
        storng: "Daily iron intake to combat hair loss?",
        text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
      },
      {
        storng:
          "Should I take iron supplements for hair loss due to iron deficiency?",
        text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
      },
      {
        storng:
          "Can I take iron supplements with other medications or supplements?",
        text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
      },
      {
        storng:
          "How long to see improvements in hair growth with an iron-rich diet?",
        text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
      },
      {
        storng:
          "Other factors contributing to hair loss besides iron deficiency? ",
        text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
      },
      {
        storng:
          "Side effects of consuming too much iron from diet or supplements? ",
        text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
      },
      {
        storng:
          "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
        text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
      },
    ],
  },
  "Diet for Thyroid":  {
    heading: "General Diet Chart for Thyroid",
    question:
    "1] Importance of Diet in Hair loss due to Thyroid?",
    subques:"What is a thyroid diet plan? And why is it necessary?",

    answer: [
      {
       
        text: "A thyroid diet plan is essential for regulating thyroid function. It should be personalized to meet individual dietary needs. For example, including iodine-rich foods can help address iodine deficiency, while selenium and zinc can support healthy thyroid function. Thus, incorporating these foods into your diet chart is crucial for managing thyroid disease.",
      },
    ],
    question1: "2] What to eat and what to avoid?",
    mainAnswer1:
    "Thyroid Diet Essentials: Eat Right, Avoid Triggers",
      
    answer1: [
      {
        strong: "",
        text: "Your thyroid, a butterfly-shaped gland in your neck, controls your metabolism by releasing hormones into your bloodstream. Issues like autoimmune disease, inflammation, or iodine deficiency can disrupt hormone secretion, leading to thyroid disease. Proper medication and a balanced diet can manage it effectively.",
      },
      {
        strong: "Foods to eat",
        text: "If you suffer from hypothyroidism or hyperthyroidism, you must be very careful about the food that you eat. Because food triggers can lead to inflammatory reactions in the gut called leaky gut.",
      },
     
    ],
    answerThroid:[
   
      {
        "strong": "Protein",
        "text": "Opt for tuna, salmon, lentils, and peas to flush out toxins, regulate blood glucose, and control hunger."
      },
      {
        "strong": "Dairy Alternatives",
        "text": "Coconut, almond, flaxseed, or hazelnut milk offer prebiotic and probiotic benefits for gut health."
      },
      {
        "strong": "Nuts",
        "text": "A serving or two of nuts can help regulate thyroid levels and combat chronic illness."
      },
      {
        "strong": "Healthy Fats",
        "text": "Use extra virgin olive oil and ghee to reduce inflammation, maintain cholesterol, and aid digestion."
      },
      {
        "strong": "Greens and Fruits",
        "text": "Include leafy greens, beetroot, carrot, and colorful fruits like blueberries and pomegranate for anti-inflammatory and detoxifying effects."
      },
      {
        "strong": "Gluten-Free Grains",
        "text": "Opt for buckwheat, brown rice, and millets like pearl millet and finger millet to regulate thyroid function and enhance detoxification pathways."
      }
    ],
    
    mainAnswer2: "B) Foods to Avoid:",
    subAnswer3:'Here are certain foods that you must avoid in your thyroid diet plan-',
    answer2: [
 
      {
        "strong": "Dairy : ",
        "text": "Skip milk, butter, yogurt, and cheese to avoid triggering your body's response."
      },
      {
        "strong": "Gluten : ",
        "text": "Cut out wheat, barley, and gluten-containing foods to prevent inflammation."
      },
      {
        "strong": "High Glycemic Foods : ",
        "text": "Steer clear of processed and refined grains to prevent insulin overproduction and inflammation."
      },
      {
        "strong": "Stimulants : ",
        "text": "Say no to coffee, soft drinks, and other stimulants that irritate your gut and contribute to inflammation, especially due to added sugars in soft drinks."
      },
      {
        "strong": "Alcohol : ",
        "text": "Pass on alcohol to prevent liver interference, weakened immunity, and inflammatory toxin buildup."
      },
      {
        "strong": "Refined Sugar : ",
        "text": "Avoid HFCS, corn sugar, and corn syrup to maintain healthy thyroid hormone secretion."
      }
    ]
    
    ,
    question2: "3) SPECIFIC PCOD DIET",
    mainAnswer3:"Looking for a customized diet plan for PCOD?",
     
      noneveg: "Lose Weight and Manage PCOS Symptoms - 7 DAY PCOS DIET PLAN:",
      answer3: [
        {
          title: "Breakfast (8:00-8:30AM) ",
          option: [
            {
              strong: "2 Idli + 1/2 cup Sāmbhar + 1 tbsp Chutney",

            },
            {
              strong: "2 Slices brown bread + 1 slice low-fat cheese + 2 boiled egg whites",
             
            },
            {
              strong: "2 Chapatis + 1/2 cup paneer green peas curry",
             
            },
            {
              strong:'2 Methi Parathas + 1 tbsp green chutney'
            },
            {
              strong:'1 cup Vegetable Oats Upma + 1/2 cup low-fat milk'
            },
            {
              strong:'1 cup Mix Veg Poha + 1/2 cup low-fat milk'
            },
            {
              strong:'2 Besan Cheelas + 1 tbsp green chutney'
            },
          ]
        },
        {
          title: "Mid-Meal (11:00-11:30AM)",
          option: [
            {
              strong: "1 cup green gram sprouts",
              
            },
            {
              strong: "1 portion of papaya",
              
            },
            {
              strong: "1/2 cup boiled black chana",
             
            },
            {
              strong: "1 orange",
             
            },
            {
              strong: "1 cup plain yogurt with raw/grilled vegetables",
             
            },
            {
              strong: "1 cup plums",
             
            },
            {
              strong: "1 cup boiled chana",
             
            },
          ]
        },
        {
          title: "Lunch (2:00-2:30PM) ",
          option: [
            {
              strong: "2 Rotis + 1/2 cup salad + Fish curry (100g) + 1/2 cup cabbage sabji",
              
            },
            {
              strong: "1 cup Veg Pulao with brown rice + 1/2 cup chicken curry + 1/2 cup buttermilk",

            },
            {
              strong: "1 cup brown rice + 1/2 cup dal + 1/2 cup Palak sabji + 1/2 cup low-fat curd",

            },
            {
              strong: "1 cup brown rice + chicken curry (150g) + 1 cup cucumber salad",

            },
            {
              strong: "1 cup brown rice or 2 medium chapatis + 1/2 cup egg curry + 1/2 cup veg raita",

            },
            {
              strong: "2 Chapatis + 1/2 cup cluster beans subji + 1/2 cup fish curry (100g)",

            },
            {
              strong: "1 cup brown rice + 1/2 cup paneer curry + 1/2 cup capsicum subji + small cup low-fat curd",

            },
          ]
        },
        {
          title: "Evening (4:00-4:30PM)",
          option: [
            {
              strong: "1 cup cut apple",
              
            },
            {
              strong: "1 cup light tea + 2 wheat rusks",
             
            },
            {
              strong: "1 guava",
              
            },
            {
              strong:"1 cup light tea + 1 cup brown rice flakes poha"
            },
            {
              strong:"1 cup boiled chana + 1 cup light tea"
            },
            {
              strong:"1 cup tea + 2 karela biscuits"
            },
            {
              strong:"1 cup strawberries"
            },
          ]
        },
        {
          title: "Dinner (8:00-8:30PM)",
          option: [
            {
              strong: "2 Rotis/chapatis + 1/2 cup bottle gourd sabji",
              
            },
            {
              strong: "2 Rotis/chapatis + 1/2 cup ladies' finger sabji",
             
            },
            {
              strong: "1 cup broken oats upma + 1/2 cup green beans subji",
              
            },
            {
              strong:"2 Wheat Rotis + 1/2 cup bitter gourd sabji"
            },
            {
              strong:"2 Rotis/chapatis + 1/2 cup mixed veg curry"
            },
            {
              strong:"2 Rotis/chapatis + 1/2 cup ridge gourd subji"
            },
            {
              strong:"1 cup broken wheat dalia khichdi + 1/2 cup green beans subji"
            },
          ]
        },
       
      ],
     
    question3: "Tips for Sticking to the 7-Day Diet Plan",
    answer4: [
      {
        text: "Plan meals ahead.",
      },
      {
        text: "Shop for groceries early.",
      },
      {
        text: "Cook in bulk and freeze extras.",
      },
      {
        text: "Have healthy snacks available.",
      },
      {
        text: "Stay hydrated.",
      },
      {
        text: "Get ample sleep.",
      },
    ],
    heading2: "4) FAQs",
    question4: "FREQUENTLY ASKED QUESTIONS FOR IRON DEFICIENCY – ANEMIA DIET?",
    answer5: [
      {
        storng: "Can an iron deficiency diet help with hair loss?",
        text: "Yes, if hair loss is due to iron deficiency anemia. Iron is crucial for hair growth, and correcting the deficiency through diet can promote regrowth.",
      },
      {
        storng: "How do I know if my hair loss is due to iron deficiency?",
        text: "Hair loss from iron deficiency may show as thinning, shedding, and texture changes. A blood test is necessary to confirm the deficiency.",
      },
      {
        storng: "Best dietary sources of iron for hair loss?",
        text: "Lean meats, beans, lentils, tofu, fortified cereals, dark leafy greens, nuts, and seeds are excellent sources. Combining heme and non-heme iron foods is beneficial.",
      },
      {
        storng: "Daily iron intake to combat hair loss?",
        text: "Adult men need about 8 mg per day, women (ages 19-50) need around 18 mg per day, and pregnant women may need more.",
      },
      {
        storng:
          "Should I take iron supplements for hair loss due to iron deficiency?",
        text: "Supplements may be recommended if dietary changes are insufficient. Consult a healthcare provider before starting supplements.",
      },
      {
        storng:
          "Can I take iron supplements with other medications or supplements?",
        text: "Iron supplements can interact with other medications and supplements. Inform your healthcare provider about all medications and supplements you're taking.",
      },
      {
        storng:
          "How long to see improvements in hair growth with an iron-rich diet?",
        text: "It may take several weeks to months of following an iron-rich diet to see improvements in hair growth and reduced hair loss.",
      },
      {
        storng:
          "Other factors contributing to hair loss besides iron deficiency? ",
        text: "Yes, factors like hormonal imbalances, genetics, stress, medical conditions, and certain medications can also cause hair loss. A comprehensive evaluation is essential.",
      },
      {
        storng:
          "Side effects of consuming too much iron from diet or supplements? ",
        text: "Excessive iron can cause nausea, vomiting, constipation, and iron toxicity. Follow recommended guidelines and avoid taking supplements without guidance.",
      },
      {
        storng:
          "Can a vegetarian or vegan diet provide enough iron to prevent hair loss? ",
        text: "Yes, a well-planned vegetarian or vegan diet can provide enough iron through plant-based sources. Combining iron-rich foods with vitamin C-rich foods enhances absorption.",
      },
    ],
  },
};
export default function Page5({value}) {
  let data=content[value]
  return (
    <div className='a4-page avoid-break page-break-1'>
      <h1 className='nutrition-h1'>3) Nutritional Management</h1>
        <h2 style={{textAlign:'center'}}>{data.heading}</h2>
        <div className=' nut-highlight'>
        <h2 className='nut-head'>{data.question}</h2>
        {data.answer.map((item,index)=>{
          return    <>
          <p key={index}>
          <strong>{item.strong}</strong> 
           {item.text}
        </p>
        {item.text1&&<p>{item.text1}</p>}
          </>
        })}
        <h2 className='nut-head'>{data.question1}</h2>
        <div className='tick-con'><img className='tick' src='/tick mark.jpg'/><h2>{data.mainAnswer1}</h2></div>
        {/* {data.answer1.map((item,index)=>{
          return    <p key={index}>
          <strong className='nut-head'>{item.strong}</strong> 
           {item.text}
        </p>
        })} */}
        
        {data.answer1.map((item, index) => (
  <div key={index}>
    {Array.isArray(item.text)||value==="Diet for Thyroid" &&<strong className='nut-head'>{item.strong}</strong>}
    {Array.isArray(item.text) ? (
      
      item.text.map((subItem, subIndex) => (
        <ul>
        <li style={{fontSize:'1.4rem'}} key={subIndex}>{subItem.text}</li></ul>
      ))
    ) : (
      <p key={index}>
          {value!=="Diet for Thyroid"&&<strong className='nut-head'>{item.strong}</strong>} 
           {item.text}
        </p>
    )}
   
  </div>
))}
{data.answerThroid&&data.answerThroid.map((item)=>{
  return <ul>
        <li style={{fontSize:'1.4rem'}} key={item}><strong className='nut-head'>{item.strong}</strong>: {item.text}</li></ul>
})}
{data.textPOCD&&data.textPOCD.map((ite)=>{
    return <ul style={{marginLeft:"4rem"}}><li style={{fontSize:'1.4rem'}} >{ite.text}</li></ul>
   })}

       <div className='tick-con'><img className='tick' src='/cross mark.jpg'/> <h2 >{data.mainAnswer2}</h2></div>
        {data.answer2.map((item,index)=>{
          return    <p key={index}>
          <strong className='nut-head'>{item.strong}</strong> 
           {item.text}
        </p>
        })}
        </div>
        
    </div>
  )
}
