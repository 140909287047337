import React, { useEffect, useState } from "react";
import Navbar from "./nav/Navbar";
import Footer from "./footer/Footer";
function Policy(props) {
  return (
    <Navbar>
      <div className="container">
        <div
          style={{
            textAlign: "center",
            padding: "40px 0 0 0",
            fontSize: "26px",
            fontWeight: "600",
          }}
        >
          Privacy Policy
        </div>

        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          Welcome to our website and mobile application accessible at
          www.hairsncares.com ("Hairsncares.com"). This privacy policy ("Privacy
          Policy") outlines the policies and procedures of Vplant Speciality
          Clinics ("we," "our," or "us") regarding the collection, usage,
          maintenance, protection, and disclosure of information, including your
          Personal Information (as defined below), on Hairsncares.com and the
          services, features, content, applications, and products we provide
          (collectively referred to as the "Services").
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          By clicking "Continue" during the sign-up process, using
          Hairsncares.com, or utilizing the Services, you confirm that you have
          read, comprehended, and consent to the privacy practices detailed in
          this Privacy Policy. You also acknowledge the collection, processing,
          transfer, manipulation, storage, disclosure, and other uses of your
          personal information as outlined in this Privacy Policy. We recommend
          reading this Privacy Policy alongside our Terms of Service, which are
          available here. Any capitalized terms used but not defined in this
          Privacy Policy have the meanings ascribed to them in the Terms.
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          This Privacy Policy is published in compliance with various laws,
          including but not limited to:
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          Section 43A of the Information Technology Act, 2000 ("IT Act").
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          Rule 4 of the Information Technology (Reasonable Security Practices
          and Procedures and Sensitive Personal Information) Rules, 2011 ("SPDI
          Rules").
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          Regulation 3(1) of the Information Technology (Intermediaries
          Guidelines and Digital Media Ethics Code) Rules, 2021 ("Intermediaries
          Guidelines").
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          General Terms:
        </div>
        <div
          style={{
            fontSize: "20px",
          }}
        >
          By accessing or using the Hairsncares.com or the Services, or by
          providing us with your information, you confirm that you have the
          legal capacity to enter into a binding contract under Indian law,
          specifically the Indian Contract Act, 1872. You also confirm that you
          have read, understood, and agreed to the practices and policies
          outlined in this Privacy Policy, and you consent to be bound by the
          Privacy Policy. If you provide us with personal information about
          other individuals, you represent that you have the authority to do so
          and to allow us to use the information in accordance with this Privacy
          Policy.
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          You hereby give your consent to our collection, use, sharing, and
          disclosure of your information as described in this Privacy Policy. We
          reserve the right to modify, amend, add, or remove portions of the
          terms of this Privacy Policy at our sole discretion, at any time. Your
          continued use of the Services or the Hairsncares.com following any
          such amendments to the Privacy Policy will be considered implicit
          acceptance of the Privacy Policy in its updated form. We encourage you
          to periodically review the Privacy Policy to stay informed about any
          changes or modifications made to its terms.
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          If you are accessing or using the Services or the Hairsncares.com from
          a location outside India, you do so at your own risk and are solely
          responsible for complying with any applicable local laws.{" "}
        </div>
        <div
          style={{
            margin: "20px 0 10px 0",
            fontSize: "20px",
          }}
        >
          If you do not agree with any of the terms and conditions of this
          Privacy Policy, please refrain from using the Hairsncares.com or any
          Services further..{" "}
        </div>
      </div>
      <Footer/>
    </Navbar>
  );
}

export default Policy;
