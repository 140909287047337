import AdminNavbar from "./AdminNavbar";
import AnalyseEdit from "./manage-website/AnalyseEdit";
import HeroEdit from "./manage-website/HeroEdit";
import ReviewEdit from "./manage-website/ReviewEdit";
import TrustEdit from "./manage-website/TrustEdit";
import WhyTrustEdit from "./manage-website/WhyTrustEdit";
import CoreEdit from "./manage-website/CoreEdit";
import BeforeAfterEdit from "./manage-website/BeforeAfterEdit";
import ShoppingFeatureEdit from './manage-website/ShoppingFeatureEdit'
import OurEpertiseEdit from './manage-website/OurExpertiseEdit'
import AboutUsEdit from './manage-website/AboutUsEdit'

// import DoctorPrescribe from '../doctor-dashboard/analysis/DoctorPrescribe'
import {useEffect, useState} from 'react'
import { useSelector } from "react-redux";
import SliderImageEdit from "../video-slider/SliderImageEdit";
import MediaEdit from "../media/MediaEdit";
import RxBlueprintEdit from "../rx-section/RxBlueprintEdit";
import { toast, ToastContainer } from "react-toastify";
import BASE_URL from "../../Config";
import SpecialistEdit from "./manage-website/SpecialistEdit";
import HappyCustomer from "./manage-website/HappyCustomer";
import ManagePrice from "./manage-website/ManagePrice";
import MarketPopEdit from "../MarketPopEdit";
import BookAppointmentEdit from "../book-appointment/BookAppointmentEdit";

function ManageWebsite() {
  const [selectedTab, setSelectedTab] = useState('HomePage');
  const content = useSelector((state) => state.content);
  const [loader, setLoader] = useState(false);  // New state for discount

  console.log("mwokorke",content)

  const [section1,setSection1] = useState()
  const [section2,setSection2] = useState()
  const [section3,setSection3] = useState()
  const [section4,setSection4] = useState()
  const [section5,setSection5] = useState()
  const [section6,setSection6] = useState()
  const [section7,setSection7] = useState()
  const [section8,setSection8] = useState()
  const [section9,setSection9] = useState()
  const [section10,setSection10] = useState()
  const [section11,setSection11] = useState()
  const [section12,setSection12] = useState()


  useEffect(() => {
    setSection1(content?.home?.section1) 
    setSection2(content?.home?.section2) 
    setSection3(content?.home?.section3) 
    setSection4(content?.home?.section4) 
    setSection5(content?.home?.section5) 
    setSection6(content?.home?.section6)
    setSection7(content?.home?.section7) 
    setSection8(content?.home?.section8) 
    setSection9(content?.home?.section9)
    setSection10(content?.home?.section10)
    setSection11(content?.home?.section11)
    setSection12(content?.home?.section12)

    
  },[content])


  const changeContent = (type) => {
    setSection1(content[type]?.section1) 
    setSection2(content[type]?.section2) 
    setSection3(content[type]?.section3) 
    setSection4(content[type]?.section4) 
    setSection5(content[type]?.section5) 
    setSection6(content[type]?.section6)
    setSection7(content[type]?.section7) 
    setSection8(content[type]?.section8) 
    setSection9(content[type]?.section9)
    setSection10(content[type]?.section10)
    setSection11(content[type]?.section11)
    setSection12(content[type]?.section12)

  }


  const handleSubmit = async (e) => {
    
    setLoader(true)
    // const formData = new FormData();
    // console.log("nnirhei",images)
    // images.forEach(image => formData.append('image', image));
    try {


      const data = {
        section1,
        section2,
        section3,
        section4,
        section5,
        section6,
        section7,
        section8,
        section9,
        section10,
        section11,
        section12,
        id : content?._id
      };
      let url =
        selectedTab === "HomePage"
          ? `${BASE_URL}/utility/editHome`
          : selectedTab === "About Us Page"
          ? `${BASE_URL}/utility/editAboutUs1`
          : selectedTab === "Our Specialists Page"
          ? `${BASE_URL}/utility/editSpecialist`
          : selectedTab === "Our Expertise Page"
          ? `${BASE_URL}/utility/editExpertise`
          : selectedTab === "Happy Customer"
          ? `${BASE_URL}/utility/editVideoCustomer`
          :  selectedTab === "Contact Us"
          ? `${BASE_URL}/utility/editContactUs` : "";
      data["id"] =
        selectedTab === "HomePage"
          ? content?.home?._id
          : selectedTab === "About Us Page"
          ? content?.aboutUs?._id : selectedTab === "Our Specialists Page"
          ? content?.specialist?._id : selectedTab === "Our Expertise Page"
          ? content?.expertise?._id : selectedTab === "Happy Customer"
          ? content?.customerVideos?._id : selectedTab === "Contact Us"
          ? content?.contactus?._id
          : "";

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        setLoader(false)
        if (response.ok) {
          const result = await response.json();
          toast.success("content update successfully");
          console.log('Product created successfully:', result);
        } else {
          toast.error(`Failed to update update: ${response.statusText}`);
          console.error('Failed to create product:', response.statusText);
        }
      } catch (error) {
        setLoader(false)
        toast.error("Please logout and login again with valid credentials.");
        console.error('Error:', error);
      }
    } catch (error) {
      setLoader(false)
      toast.error("Please logout and login again with valid credentials.");
      console.error('Error:', error);
    }
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <AdminNavbar>
      <div className="test-link container">
        <div className="test-link-item">
          <div
            onClick={() => {
              changeContent("home");
              handleTabChange("HomePage")
            }}
            className={`tab-3 tab tab2 ${
              selectedTab === "HomePage" ? "selected1" : ""
            }`}
          >
            HomePage
          </div>
          <div
            onClick={() => {
              changeContent("aboutUs");
              handleTabChange("About Us Page");
            }}
            className={`tab-1 tab tab2 ${
              selectedTab === "About Us Page" ? "selected1" : ""
            }`}
          >
            About Us Page
          </div>
          <div
            onClick={() => {
              changeContent("specialist");
              handleTabChange("Our Specialists Page");
            }}
            className={`tab-4 tab tab2 ${
              selectedTab === "Our Specialists Page" ? "selected1" : ""
            }`}
          >
            Our Specialists Page
          </div>

          <div
            onClick={() => {
              changeContent("expertise");
              handleTabChange("Our Expertise Page");
            }}
            className={`tab-2 tab tab2 ${
              selectedTab === "Our Exepertise Page" ? "selected1" : ""
            }`}
          >
            Our Exepertise Page
          </div>


          <div
            onClick={() => {
              changeContent("customerVideos");
              handleTabChange("Happy Customer");
            }}
            className={`tab-2 tab tab2 ${
              selectedTab === "Happy Customer" ? "selected1" : ""
            }`}
          >
            Happy Customer
          </div>
          <div
            onClick={() => {
              changeContent("plan");
              handleTabChange("Hair-test-price");
            }}
            className={`tab-2 tab tab2 ${
              selectedTab === "Hair-test-price" ? "selected1" : ""
            }`}
          >
            Hair-test price
          </div>
          <div
            onClick={() => {
              changeContent("contactus");
              handleTabChange("Contact Us");
            }}
            className={`tab-2 tab tab2 ${
              selectedTab === "Contact Us" ? "selected1" : ""
            }`}
          >
            Contact Us
          </div>
        </div>
      </div>
      {selectedTab === "HomePage" && (
        <div>
          <HeroEdit section1={section1} setSection1={setSection1} />
          <TrustEdit section2={section2} setSection2={setSection2} />
          <ReviewEdit section3={section3} setSection3={setSection3} />
          <MediaEdit section5={section5} setSection5={setSection5} />
          <AnalyseEdit section7={section7} setSection7={setSection7} />
          <WhyTrustEdit section4={section4} setSection4={setSection4} />
          <CoreEdit section6={section6} setSection6={setSection6} />
          <RxBlueprintEdit section8={section8} setSection8={setSection8} />
          <ShoppingFeatureEdit section9={section9} setSection9={setSection9} />
          <BeforeAfterEdit section10={section10} setSection10={setSection10} />
          <SliderImageEdit section11={section11} setSection11={setSection11} />
          <MarketPopEdit section12={section12} setSection12={setSection12} />

        </div>
      )}
      {selectedTab === "About Us Page" && (
        <AboutUsEdit
          section1={section1}
          setSection1={setSection1}
          section2={section2}
          setSection2={setSection2}
          section3={section3}
          setSection3={setSection3}
          section4={section4}
          setSection4={setSection4}
          section5={section5}
          setSection5={setSection5}
          section6={section6}
          setSection6={setSection6}
        />
      )}
      {selectedTab === "Our Specialists Page" && <SpecialistEdit           section1={section1}
          setSection1={setSection1}
          section2={section2}
          setSection2={setSection2}
          section3={section3}
          setSection3={setSection3}
          section4={section4}
          setSection4={setSection4}
          section5={section5}
          setSection5={setSection5} />}
      {selectedTab === "Our Expertise Page" && <OurEpertiseEdit           section1={section1}
          setSection1={setSection1}
          section2={section2}
          setSection2={setSection2}
          section3={section3}
          setSection3={setSection3}
          section4={section4}
          setSection4={setSection4}
          section5={section5}
          setSection5={setSection5}
          section6={section6}
          setSection6={setSection6}
            section7={section7}
            setSection7={setSection7
          }
           />}
                        {selectedTab === "Happy Customer" && <HappyCustomer            section1={section1}
          setSection1={setSection1}
           />}     
             {selectedTab === "Hair-test-price" && <ManagePrice  />}
             {selectedTab === "Contact Us" && <BookAppointmentEdit            section1={section1}
          setSection1={setSection1}
          section2={section2}
          setSection2={setSection2}
          section3={section3}
          setSection3={setSection3}
           />}   
             {
              selectedTab != "Hair-test-price" ?                     <div>
              <button
                style={{ background: "bisque", cursor: "pointer" }}
                onClick={() => handleSubmit()}
                className="btn"
              >
                {loader ? "loadin" : "Update Data"}
              </button>
            </div> : <></>
             }   

                   

      <ToastContainer  position="bottom-right"/>
    </AdminNavbar>
  );
}

export default ManageWebsite;
